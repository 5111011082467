import * as React from 'react';
import { Component } from 'react';
import { Button, Grid, InputAdornment, IconButton, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import i18next from 'i18next'; 
import { UserService } from 'services';
import { StatusMessage } from 'models';
import Header from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Body } from 'components/body/body';
import { Page } from 'components/page/page';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { ChangePasswordProps } from './change-password-props';
import { ChangePasswordState } from './change-password-state';
import './change-password.css';

export class ChangePassword extends Component<ChangePasswordProps, ChangePasswordState> {
    constructor(props: ChangePasswordProps) {
        super(props);
        this.state = new ChangePasswordState();
    }

    private onBranchSelected(branchId) {
        this.props.onBranchSelected(branchId);
        this.hideStatusMessage();
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private updatePassword(newValue: string, type: string) {
        if (type === 'old') {
            this.setState({ 
                ...this.state, 
                oldPassword: newValue,
                errors: {
                    ...this.state.errors,
                    oldPassword: this.isFieldEmpty(newValue)
                }
            });
        } else if (type === 'new') {
            this.setState({ 
                ...this.state, 
                newPassword: newValue,
                errors: {
                    ...this.state.errors,
                    newPassword: this.isFieldEmpty(newValue) || !this.isValidPassword(newValue),
                    matching: !this.isMatchingPassword(newValue, this.state.confirmPassword)
                }
            });
        } else if (type === 'confirm') {
            this.setState({
                ...this.state,
                confirmPassword: newValue,
                errors: {
                    ...this.state.errors,
                    confirmPassword: this.isFieldEmpty(newValue) || !this.isValidPassword(newValue),
                    matching: newValue !== this.state.newPassword
                }
            })
        }

    }

    private handleClickShowPassword(type: string) {
        if (type === 'old') {
            this.setState({ ...this.state, showOldPassword: !this.state.showOldPassword });
        } else if (type === 'new') {
            this.setState({ ...this.state, showNewPassword: !this.state.showNewPassword });
        } else if (type === 'confirm') {
            this.setState({ ...this.state, showConfirmPassword: !this.state.showConfirmPassword });
        }
    }

    private handleMouseDownPassword(e) {
        e.preventDefault();
    }

    private isFieldEmpty(value: string) {
        if (value === undefined || value === null || value === '') {
            return true;
        }
        return false;
    }

    private isValidPassword(value: string) {
        let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{6,}$/; // maximum 6 chars, contain uppercase letter and lowercase letters, and number, /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#?!@$%^&*-,.]{6,}$/
        return pattern.test(value);
    }

    private isMatchingPassword(newP: string, confirmP: string) { // for having confirmP, and then newP changes
        if (confirmP === null || confirmP === undefined || confirmP === '') {
            return true;
        } else {
            if (confirmP === newP) {
                return true;
            } else {
                return false;
            }
        }
    }

    private async verify() {
        if (!this.props.user || !this.state.oldPassword || !this.state.newPassword || !this.state.confirmPassword) {
            return;
        }

        try {

            this.setState({ loading: true });
            this.hideStatusMessage();
            await UserService.changePasswordVerification(this.props.user.token, this.state.oldPassword, this.state.newPassword);
            this.showStatusMessage(new StatusMessage(i18next.t('NOTE_PWD_UPDATE_SUCCESS'), 'success'));

        } catch(error) {
            
            if (error.message.includes('403')) { // 'forbid'
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_PWD_UPDATE_FORBID_FAILED'), 'error'));
            } else if (error.message.includes('401')) {
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_PWD_UPDATE_UNAUTHERIAED_FAILED'), 'error'));
            } else if (error.message.includes('404')) { // not found
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_PWD_UPDATE_NOT_FOUND_FAILED'), 'error'));
            } else {
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_PWD_UPDATE_OTHER_FAILED'), 'error'));
            }

        } finally {

            this.setState({ loading: false });

        }
    }

    private disableSaveButton() {
        let noInput = !this.state.oldPassword || !this.state.newPassword || !this.state.confirmPassword;
        let notValid = this.state.errors.oldPassword || this.state.errors.newPassword || this.state.errors.confirmPassword || this.state.errors.matching;
        return noInput || notValid;
    }

    private goToMonthlyReports() {
        this.props.route.history.replace('/');
    }

    render() {

        return (
            <Page>
                <Header
                    route={this.props.route}
                    title={i18next.t('CHANGE_PASSWORD')}
                    branches={this.props.user.branches}
                    branchId={this.props.user.branchId}
                    onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                    user={this.props.user}
                    onLogout={(user) => this.props.onLogout(user)}
                    siteLocale={this.props.siteLocale}
                    handleLangChange={(selectedLocale) => this.props.handleLangChange(selectedLocale)}
                />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }

                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar />
                            :
                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type={this.state.showOldPassword ? 'text' : 'password'}
                                        error={this.state.errors.oldPassword}
                                        label={i18next.t('OLD_PASSWORD')}
                                        value={this.state.oldPassword}
                                        onChange={(e: any) => this.updatePassword(e.target.value, 'old')}
                                        helperText={this.state.errors.oldPassword ? i18next.t('NOTE_PWD_UPDATE_OLDPWD_ERROR_MSG') : ""}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle old password visibility"
                                                    onClick={() => this.handleClickShowPassword('old')}
                                                    onMouseDown={(e: any) => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type={this.state.showNewPassword ? 'text' : 'password'}
                                        error={this.state.errors.newPassword}
                                        label={i18next.t('NEW_PASSWORD')}
                                        value={this.state.newPassword}
                                        onChange={(e: any) => this.updatePassword(e.target.value, 'new')}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new password visibility"
                                                    onClick={() => this.handleClickShowPassword('new')}
                                                    onMouseDown={(e: any) => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                        fullWidth 
                                    />
                                    {this.state.errors.newPassword ? 
                                        <div className="change-password-new-password-eror-container">
                                            <p className="change-password-new-password-error-header">{i18next.t('NOTE_PWD_UPDATE_NEWPWD_ERROR_MSG')}</p>
                                            <ul className="change-password-new-password-error-list">
                                                <li>{i18next.t('NOTE_PWD_UPDATE_NEWPWD_ERROR_MSG_LEN')}</li>
                                                <li>{i18next.t('NOTE_PWD_UPDATE_NEWPWD_ERROR_MSG_UPPER')}</li>
                                                <li>{i18next.t('NOTE_PWD_UPDATE_NEWPWD_ERROR_MSG_LOWER')}</li>
                                                <li>{i18next.t('NOTE_PWD_UPDATE_NEWPWD_ERROR_MSG_DIGIT')}</li>
                                            </ul>

                                        </div>
                                            
                                        : 
                                        ""
                                    } 
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        variant="outlined"
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        error={this.state.errors.confirmPassword || this.state.errors.matching}
                                        label={i18next.t('CONFIRM_PASSWORD')}
                                        value={this.state.confirmPassword}
                                        onChange={(e: any) => this.updatePassword(e.target.value, 'confirm')}
                                        helperText={(this.state.errors.confirmPassword || this.state.errors.matching) ? i18next.t('NOTE_PWD_UPDATE_CONFIRMPWD_ERROR_MSG') : ""}
                                        InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle new password visibility"
                                                    onClick={() => this.handleClickShowPassword('confirm')}
                                                    onMouseDown={(e: any) => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                        }}
                                        fullWidth 
                                    />
                                </Grid>
                            </Grid>
                    }
                </Body>

                <Footer>
                    <Button variant="contained" color="primary" disableElevation fullWidth disabled={this.disableSaveButton()} onClick={() => this.verify()}> 
                        {i18next.t('BUTTON_SAVE')}
                    </Button>
                    <Button variant="contained" color="secondary" disableElevation fullWidth onClick={(e: any) => this.goToMonthlyReports()}>
                        {i18next.t('BUTTON_CANCEL')}
                    </Button>
                </Footer>

            </Page>
        );
    }
}
