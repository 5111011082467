import * as React from 'react';
import { Component } from 'react';
import {
    Toolbar, AppBar, Drawer, Box, IconButton, Tooltip, TextField,
    Typography, Dialog, DialogTitle, DialogContent, DialogContentText,
    DialogActions, Button, Link
} from '@material-ui/core';
import i18next from 'i18next';
import { Autocomplete, ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { Menu, ArrowBackIos, SaveAlt } from '@material-ui/icons';
import { UserBranch, UserLoginType } from 'models/api/responses';
import { HeaderState } from './header-state';
import { HeaderProps } from './header-props';
import './header.css'
import { withStyles } from "@material-ui/styles";

const styles = theme => ({
    paper: {
        right: '0%',
        top: '0%',
        height: '100vh',

        [theme.breakpoints.up('md')]: {
            right: '10%',
            top: '10%',
            height: '80vh',
        },
    },
});

class Header extends Component<HeaderProps, HeaderState> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = new HeaderState();
    }

    goBack() {
        if (this.props.route != null) {
            const pathname = this.props.route.location.state?.from ?? '/';
            this.props.route.history.replace(pathname, {
                year: this.props.route.location.state?.year,
                month: this.props.route.location.state?.month
            });
        }
    }

    logOut() {
        // reset user state
        if (this.props.onLogout && this.props.user) {
            this.props.onLogout(this.props.user);
        }
    }

    handleAlignment(e, newLang) {
        if (this.props.handleLangChange) {
            this.props.handleLangChange(newLang);
        }
    }

    redirectToChangePassword() {
        if (this.props.route != null) {
            this.props.route.history.replace('/change-password');
        }
    }

    render() {
        let pathname = '';
        if (this.props.route != null) {
            pathname = this.props.route.location.pathname;
        }

        const showBackButton = pathname !== '/' && pathname !== '/login' && pathname !== '/branch' && pathname !== '/feedback' && pathname !== '/change-password' && pathname !== '/forgot-password';
        const showHamburgerButton = this.props.showHamburgerButton ?? true;
        const showExportButton = this.props.showExportButton ?? false;
        const branch = this.props.branches?.find(x => x.branchId === this.props.branchId);
        const { classes } = this.props;

        return (
            <React.Fragment>
                <AppBar position="static" color="transparent" elevation={1} square={true}>
                    <Toolbar variant="dense">
                        {
                            showBackButton ?
                                <Tooltip title={i18next.t('TOOLTIP_BACK')}>
                                    <IconButton edge="start" onClick={(e) => this.goBack()}>
                                        <ArrowBackIos />
                                    </IconButton>
                                </Tooltip>
                                :
                                null
                        }
                        <Box display="flex" alignItems="center" flexGrow="1">
                            <Typography display="inline" align="center" variant="subtitle1">
                                <b>{this.props.title}</b>
                            </Typography>
                            {
                                showExportButton ?
                                    <Tooltip title={i18next.t('TOOLTIP_EXPORT')}>
                                        <IconButton className="export-button" color="primary" aria-label="menu" onClick={(e) => this.setState({ showExportDialog: true })}>
                                            <SaveAlt />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    null
                            }
                        </Box>
                        {this.props.children}
                        {
                            showHamburgerButton ?
                                <IconButton edge="end" color="inherit" aria-label="menu" onClick={(e) => this.setState({ showDrawer: true })}>
                                    <Menu />
                                </IconButton>
                                :
                                null
                        }
                    </Toolbar>
                </AppBar>
                {
                    showHamburgerButton ?
                        <Drawer open={this.state.showDrawer} onClose={(e) => this.setState({ showDrawer: false })} anchor="right" transitionDuration={0}
                            classes={{
                                paper: classes.paper,
                            }}
                        >
                            <Box padding={2}>
                                <Autocomplete className="branch-selector"
                                    disableClearable={true}
                                    value={branch}
                                    options={this.props.branches!}
                                    getOptionLabel={(option: UserBranch) => option.branchName}
                                    renderInput={params => <TextField {...params} label={i18next.t('FOOD_BANK')} />}
                                    onChange={(e: React.ChangeEvent<{}>, branch: UserBranch | null) => { this.props.onBranchSelected!(branch!.branchId); this.setState({ showDrawer: false }); }} />
                            </Box>

                            <ToggleButtonGroup
                                className={(this.props.user && this.props.user.workplace === false) ? "header-lng-switch" : "header-lng-switch-workplace"}
                                value={this.props.siteLocale}
                                exclusive
                                onChange={(e, newLang) => this.handleAlignment(e, newLang)}
                                aria-label="language switch"
                            >
                                <ToggleButton value="en" aria-label="lng-en">
                                    <label>EN</label>
                                </ToggleButton>
                                <ToggleButton value="fr" aria-label="lng-fr">
                                    <label>FR</label>
                                </ToggleButton>
                            </ToggleButtonGroup>

                            {
                                (this.props.user && this.props.user.workplace === false && this.props.user.loginType === UserLoginType.Admin) ?
                                    <Link
                                        className="header-change-password-link"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            this.redirectToChangePassword();
                                        }}
                                    >
                                        {i18next.t('CHANGE_PASSWORD')}
                                    </Link>
                                    :
                                    null
                            }

                            {
                                (this.props.user && this.props.user.workplace === false) ?
                                    <Link
                                        className="header-logout-link"
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                            this.logOut();
                                        }}
                                    >
                                        {i18next.t('BUTTON_LOGOUT')}
                                    </Link>
                                    :
                                    null
                            }

                        </Drawer>
                        :
                        null
                }
                {
                    showExportButton ?
                        <Dialog
                            open={this.state.showExportDialog}
                            onClose={() => this.setState({ showExportDialog: false })}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description">
                            <DialogTitle id="alert-dialog-title">{i18next.t("NOTE_DONATION_EXPORT_CONFIRM")}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {i18next.t("NOTE_DONATION_EXPORT_CONFIRM_MSG")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({ showExportDialog: false })} color="primary">
                                    {i18next.t('BUTTON_CANCEL')}
                                </Button>
                                <Button onClick={() => { this.setState({ showExportDialog: false }); this.props.onExportDonations!(); }} color="primary" autoFocus>
                                    {i18next.t('TOOLTIP_EXPORT')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        :
                        null
                }
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Header);