import * as React from 'react';
import i18next from 'i18next';
import { Component } from 'react';
import { Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import { DateBox } from 'components/date-box/date-box';
import { MonthlyReportsListItemProps } from './monthly-reports-list-item-props';

export class MonthlyReportsListItem extends Component<MonthlyReportsListItemProps, {}> {
    viewDonations() {
        this.props.route.history.replace(`/donations/${this.props.report.year}/${this.props.report.month}`, {
            from: this.props.route.location.pathname,
            year: this.props.report.year
        });
    }

    render() {
        let frontStoreWeight = `${(this.props.report.frontStoreWeightInPounds).toLocaleString(navigator.language)} lbs.`;
        let backStoreWeight = `${(this.props.report.backStoreWeightInPounds).toLocaleString(navigator.language)} lbs.`;

        let categories: string[] = [];

        for (const [categoryName, fraction] of Object.entries(this.props.report.categories)) {
            if (fraction) {
                categories.push(`${(fraction! * 100).toFixed(2)}% ${i18next.t("LABEL_" + categoryName.split('&').join('').split(' ').join('_').toUpperCase())}`);
            }
        }

        let submitted = '';
        if (this.props.report.submitted) {
            submitted = i18next.t('STATUS_SUBMITTED');
        }
        else {
            let today = new Date();
            let twoWeeksAfterEndofReportMonth = new Date(this.props.report.year, this.props.report.month, 15);
            if (twoWeeksAfterEndofReportMonth < today) {
                submitted = i18next.t('STATUS_OVERDUE');
            }
            else {
                submitted = i18next.t('STATUS_PENDING');
            }
        }

        return (
            <ListItem button onClick={() => this.viewDonations()}>
                <ListItemText>
                    <Grid container direction="row" spacing={2} wrap="nowrap">
                        <Grid item>
                            <DateBox month={this.props.report.month - 1} />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle1">
                                <div>
                                    <b>{i18next.t('LABEL_FRONT_STORE')}: {frontStoreWeight}</b>
                                </div>
                                <div>
                                    <b>{i18next.t('LABEL_BACK_STORE')}: {backStoreWeight}</b>
                                </div>
                            </Typography>
                            {
                                /*
                                 * Remove this so the UI is not cluttered
                                categories.map((c, i) => <div key={i}>{c}</div>)
                                */
                            }
                            <div style={submitted === i18next.t('STATUS_OVERDUE') ? { color: 'red', fontWeight: 'bold' } : {}}>{submitted}</div>
                        </Grid>
                    </Grid>
                </ListItemText>
            </ListItem>
        );
    }
}