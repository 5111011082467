export class MonthlyReport {
	year: number;
	month: number;
	frontStoreWeightInPounds: number;
	backStoreWeightInPounds: number;
	categories: Map<string, number>;
	submitted: boolean;

	constructor(year: number, month: number, frontStoreWeightInPounds: number, backStoreWeightInPounds: number, categories: Map<string, number>, submitted: boolean) {
		this.year = year;
		this.month = month;
		this.frontStoreWeightInPounds = frontStoreWeightInPounds;
		this.backStoreWeightInPounds = backStoreWeightInPounds;
		this.categories = categories;
		this.submitted = submitted;
	}
}