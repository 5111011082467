import { Retailer } from "./retailer";
import { Banner } from "./banner";

export class Store {
	id: string;
	name: string;
	number: string;
	retailer: Retailer | null;
	banner: Banner | null;

	constructor(id: string, name: string, number: string, retailer: Retailer | null, banner: Banner | null) {
		this.id = id;
		this.name = name;
		this.number = number;
		this.retailer = retailer;
		this.banner = banner;
	}
}