import { StatusMessage } from "models";

export class ChangePasswordState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;

    showOldPassword: boolean;
    showNewPassword: boolean;
    showConfirmPassword: boolean;

    showStatusMessage: boolean;
    statusMessage: StatusMessage;

    loading: boolean;
    errors: ValidationErrors;

    constructor() {
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';

        this.showOldPassword = false;
        this.showNewPassword = false;
        this.showConfirmPassword = false;

        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();

        this.loading = false;
        this.errors = new ValidationErrors();
    }
}

class ValidationErrors {
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
    matching: boolean;

    constructor() {
        this.oldPassword = false;
        this.newPassword = false;
        this.confirmPassword = false;
        this.matching = false;
    }
}