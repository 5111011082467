import * as React from 'react';
import { Component } from 'react';
import {
	Box,
	CircularProgress
} from '@material-ui/core';

export class LoadingBar extends Component<any, any> {
	render() {
		return (
			<Box height="100%" display="flex" justifyContent="center" alignItems="center">
				<CircularProgress size={120}/>
			</Box>
		);
	}
}