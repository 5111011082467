import { CategoryService, DonationService } from 'services';

export class ExportUtilities {
    public static async exportDonations(token: string, branchId: string, year: number, branchName: string | null = null): Promise<void> {
        var downloadElement = document.querySelector('#download-csv');
        if (downloadElement != null) {
            downloadElement.remove();
        }

        var categories = await CategoryService.getCategories(token);
        var donationExports = await DonationService.getDonationsByBranchAndYear(token, branchId, year);

        // track position of category
        var categoryIndex = new Map<string, number>();

        // add headers
        var csv = 'Retailer,Banner,Store #,Store Name,Food Bank,Date,Source,Weight (lbs)';
        if (categories != null && categories.length > 0) {
            for (var i = 0; i < categories.length; i++) {
                csv += `,${categories[i].name}`;

                categoryIndex.set(categories[i].id, i);
            }
        }
        csv += '\r\n';

        // add donation data
        for (var donation of donationExports) {
            csv += `${donation.store?.retailer?.name},${donation.store?.banner?.name ?? 'N/A'},${donation.store?.number},${ExportUtilities.escapeComma(donation.store?.name)},${branchName},${ExportUtilities.formatDateString(donation.date)},${donation.source == 0 ? 'Back Of Store' : 'Front of Store'},${donation.weightInPounds},`;

            // add category data
            if (categories != null && categories.length > 0) {
                var nonNullCategories = donation.categories.findIndex(c => c.fraction != null);
                if (nonNullCategories >= 0) {
                    var categoryList: string[] = new Array(categories.length).fill('0%');
                    for (var donationCategory of donation.categories) {
                        if (donationCategory.fraction != null) {
                            if (categoryIndex.has(donationCategory.categoryId)) {
                                var index = categoryIndex.get(donationCategory.categoryId)!;
                                categoryList[index] = ExportUtilities.formatDecimalAsPercentage(donationCategory.fraction);
                            }
                        }                        
                    }
                    csv += categoryList.join();
                }
            }
            csv += '\r\n';
        }

        // download
        var filename = `${year}-branch-donations-export.csv`;
        var link = document.createElement('a')
        link.id = 'download-csv'
        link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        (document.querySelector('#download-csv')! as HTMLAnchorElement).click();
    }

    private static escapeComma(item: string | undefined) {
        if (item == null) {
            return '';
        }

        if (item.indexOf(',') >= 0) {
            return `"${item}"`;
        }
        return item;
    }

    private static formatDecimalAsPercentage(value: number) {
        if (isNaN(value)) {
            return `0%`;
        } else {
            return `${Math.round(value * 100)}%`
        }
    }

    private static formatDateString(date: Date) {
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var monthString = month.toString();
        var day = date.getDate();
        var dayString = day.toString();

        if (day < 10) {
            dayString = '0' + dayString;
        }
        if (month < 10) {
            monthString = '0' + monthString;
        }

        return `${monthString}/${dayString}/${year}`;
    }
}