import React, { useState, useEffect } from 'react';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { StatusBar } from 'components/status-bar/status-bar';
import { UserService } from './services';
import { StatusMessage } from 'models';
import { UserLoginType } from 'models/api/responses';
import './layout.css'

export default (props) => {
    const [doesShowStatusMessage, setDoesShowStatusMessage] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage>(new StatusMessage());
    const fbcBgCookie = props.cookies.get('fbc-bg');
    const fbcBgFrCookie = props.cookies.get('fbc-bg-fr');
    const fbcBgDataCookie = { logo: fbcBgCookie?.logo, blackLetterLogo: fbcBgCookie?.blackLetterLogo, title: fbcBgCookie?.title, content: fbcBgCookie?.content, bgImage: fbcBgCookie?.bgImage, contentWidth: fbcBgCookie?.contentWidth, frLogo: fbcBgFrCookie?.frLogo, frBlackLetterLogo: fbcBgFrCookie?.frBlackLetterLogo, frTitle: fbcBgFrCookie?.frTitle, frContent: fbcBgFrCookie?.frContent, frBgImage: fbcBgFrCookie?.frBgImage, frContentWidth: fbcBgFrCookie?.frContentWidth } as object;
    const [fbcBgData, setFbcBgData] = useState(fbcBgCookie ? fbcBgDataCookie : '');
    const isEnglish = props.siteLocale === 'en';
    const logo = isEnglish ? (fbcBgData as any).logo : (fbcBgData as any).frLogo;
    const blackLetterLogo = isEnglish ? (fbcBgData as any).blackLetterLogo : (fbcBgData as any).frBlackLetterLogo;
    const title = isEnglish ? (fbcBgData as any).title : (fbcBgData as any).frTitle;
    const content = isEnglish ? (fbcBgData as any).content : (fbcBgData as any).frContent;
    const bgImage = isEnglish ? (fbcBgData as any).bgImage : (fbcBgData as any).frBgImage;
    const contentWidth = isEnglish ? (fbcBgData as any).contentWidth : (fbcBgData as any).frContentWidth;

    const adminQuery = new URLSearchParams(window.location.search).get("adminLogin");
    const isAdminLoginPage = (adminQuery === 'true' || adminQuery === "True");
    const isLoginPage = (window.location.pathname === '/login' || ((props.user === null || props.user === undefined) && window.location.pathname === '/'));
    const isAdminLogin = props.user?.loginType === UserLoginType.Admin;
    const isWorkPlaceLogin = props.user?.workplace;

    useEffect(() => {
        const getBgData = async (): Promise<void> => {
            try {
                const data = await UserService.getBgData();

                if (data != null || data === undefined || (data as any).logo !== (fbcBgData as any).logo || (data as any).blackLetterLogo !== (fbcBgData as any).blackLetterLogo || (data as any).title !== (fbcBgData as any).title || (data as any).content !== (fbcBgData as any).content || (data as any).bgImage !== (fbcBgData as any).bgImage || (data as any).contentWidth !== (fbcBgData as any).contentWidth || (data as any).frLogo !== (fbcBgData as any).frLogo || (data as any).frBlackLetterLogo !== (fbcBgData as any).frBlackLetterLogo || (data as any).frTitle !== (fbcBgData as any).frTitle || (data as any).frContent !== (fbcBgData as any).frContent || (data as any).frBgImage !== (fbcBgData as any).frBgImage || (data as any).frContentWidth !== (fbcBgData as any).frContentWidth) {
                    setFbcBgData(data);
                    const enData = { logo: data.logo, blackLetterLogo: data.blackLetterLogo, title: data.title, content: data.content, bgImage: data.bgImage, contentWidth: data.contentWidth };
                    const frData = { frLogo: data.frLogo, frBlackLetterLogo: data.frBlackLetterLogo, frTitle: data.frTitle, frContent: data.frContent, frBgImage: data.frBgImage, frContentWidth: data.frContentWidth };
                    props.cookies.set('fbc-bg', enData, { path: '/', sameSite: 'none', secure: true });
                    props.cookies.set('fbc-bg-fr', frData, { path: '/', sameSite: 'none', secure: true });
                }
            } catch (e) {
                console.log(e.message);
            }
        }

        if (!isWorkPlaceLogin) {
            getBgData();
        }
    }, []);

    React.useEffect(() => {
        if (props.twoFactorErrorMessage) {
            showStatusMessage(new StatusMessage(props.twoFactorErrorMessage, 'error'));
        }
    }, [props.twoFactorErrorMessage])

    const converIntoJSX = (htmlString: any) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: htmlString }} />
        );
    }

    const handleAlignment = (e, newLang) => {
        if (props.handleLangChange) {
            props.handleLangChange(newLang);
        }
    }

    const showStatusMessage = (statusMessage: StatusMessage) => {
        setDoesShowStatusMessage(true);
        setStatusMessage(statusMessage);
    }

    const hideStatusMessage = () => {
        setDoesShowStatusMessage(false);
    }

    const displayLogoTitleHeader = () => {
        if (isAdminLogin || isAdminLoginPage) {
            return <div className="logo-title-header">
                <img src={blackLetterLogo} alt="layout black letterlogo" className="logo-title-header-logo" />
                <div className="logo-title-header-title">
                    {title}
                </div>
            </div>
        } else if (isWorkPlaceLogin) {
            return <></>;
        } else {
            if (!isLoginPage) {
                return <div className="logo-title-header">
                    <img src={blackLetterLogo} alt="layout black letterlogo" className="logo-title-header-logo" />
                    <div className="logo-title-header-title">
                        {title}
                    </div>
                </div>
            } else {
                return null;
            }
        }
    }

    return <div className="wrapperLayout" >
        {props.fromWorkplace ? <>
            {props.children}
        </>
            :
            <div style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})` }} className={`${isAdminLoginPage ? 'adminLayout' : 'layout'} ${doesShowStatusMessage ? 'errorLayout' : ''} ${props.user === null ? 'beforeLoginLayout' : ''} `}>
                {
                    doesShowStatusMessage ? <StatusBar show={doesShowStatusMessage} message={statusMessage} onClose={() => hideStatusMessage()} />
                        : <></>
                }
                {displayLogoTitleHeader()}
                {isLoginPage && !props.twoFactorErrorMessage ?
                    <ToggleButtonGroup
                        className={isAdminLoginPage ? 'layout-lng-buttons-admin layout-lng-buttons' : 'layout-lng-buttons'}
                        value={props.siteLocale}
                        exclusive
                        onChange={(e, newLang) => handleAlignment(e, newLang)}
                        aria-label="language switch"
                    >
                        <ToggleButton value="en" aria-label="lng-en">
                            <label>EN</label>
                        </ToggleButton>
                        <ToggleButton value="fr" aria-label="lng-fr">
                            <label>FR</label>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    : null
                }
                {
                    (!isAdminLoginPage && isLoginPage) ?
                        <>
                            <div className="contentWrapper" >
                                {
                                    content !== '' ? <div className={content ? 'content' : 'beforeContent'} style={{ width: `${contentWidth}%` }}>
                                        {logo ? <img src={logo} alt="layout logo" className="layout-logo" /> : null}
                                        <div className="title">{title}</div>
                                        {converIntoJSX(content)}
                                        <div className="buttonWrapper">{props.children}</div>
                                    </div>
                                        :
                                        <div className={content ? 'content' : 'beforeContent'} style={{ width: `${contentWidth}%` }}>
                                            <div className="buttonWrapper">{props.children}</div>
                                        </div>
                                }
                            </div>
                        </>
                        : <>
                            <div className="adminContentWrapper">
                                {
                                    content !== '' ? <div className="adminContent" style={{ width: `${contentWidth}%` }}>
                                        {logo ? <img src={logo} alt="layout logo" /> : null}
                                        <div className="title">{title}</div>
                                        {converIntoJSX(content)}
                                    </div> : null
                                }
                            </div>
                            <div className="children">
                                {props.children}
                            </div>
                        </>
                }
            </div>
        }
    </div >
}
