import { API } from "models";

export class BranchService {
    public static async insertDonation(token: string, branchId: string, donation: API.Requests.Donation): Promise<API.Responses.Donation> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donation)
        };

        let response = await fetch(`/api/branch/${branchId}/donations`, options);

        if (!response.ok) {
            if (response.status === 400) {
                let errorJson = await response.json();
                throw new Error(errorJson.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        let json = await response.json();

        return new API.Responses.Donation(json.id, json.branchId, new Date(json.date), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, json.categories, json.submitted, json.submitDate);
    }

    public static async insertCampaignDonation(token: string, campaignId: string, branchId: string, donation: API.Requests.Donation): Promise<API.Responses.Donation> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donation)
        };

        let response = await fetch(`/api/branch/${branchId}/${campaignId}/campaigndonations`, options);
        
        if (!response.ok) {
            if (response.status === 400) {
                let errorJson = await response.json();
                throw new Error(errorJson.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        let json = await response.json();

        return new API.Responses.Donation(json.id, json.branchId, new Date(json.date), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, json.categories, json.submitted, json.submitDate);
    }

    public static async getBranch(token: string, branchId: string): Promise<API.Responses.Branch> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}`, options);
        let json = await response.json();
        return new API.Responses.Branch(
            json.id,
            json.name,
            json.email,
            json.phoneNumber,
            json.province,
            json.city,
            json.address1,
            json.address2,
            json.postalCode,
            json.lat,
            json.lng,
            json.website,
            json.operatingHours,
            json.contactName,
            json.contactTitle,
            json.contactEmail,
            json.contactPhoneNumber,
            json.numStaff,
            json.numVolunteers,
            json.numServedInPreviousYear,
            json.totalPoundsDistributedAnnually
        );
    }

    public static async getDonations(token: string, branchId: string, year: number, month: number): Promise<API.Responses.Donation[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/donations/${year}/${month}`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let donations: API.Responses.Donation[] = [];

        for (let d of json) {
            donations.push(new API.Responses.Donation(d.id, d.branchId, new Date(d.date + 'Z'), d.storeId, d.store, d.source, d.weightInPounds, d.weight, d.weightUnit, d.categories, d.submitted, d.submitDate));
        }

        return donations;
    }

    public static async getStores(token:string, branchId: string): Promise<API.Responses.Store[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/stores`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let stores: API.Responses.Store[] = [];

        for (let s of json) {
            stores.push(new API.Responses.Store(s.id, s.name, s.number, s.retailer, s.banner));
        }

        return stores;
    }

    public static async getCampaignStores(token: string, branchId: string, campaignId: string): Promise<API.Responses.Store[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/${campaignId}/stores`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let stores: API.Responses.Store[] = [];

        for (let s of json) {
            stores.push(new API.Responses.Store(s.id, s.name, s.number, s.retailer, s.banner));
        }

        return stores;
    }

    public static async getMonthlyReports(token:string, branchId: string, year: number): Promise<API.Responses.MonthlyReport[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/donations/${year}/aggregate`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let summaries: API.Responses.MonthlyReport[] = [];

        for (let d of json) {
            summaries.push(new API.Responses.MonthlyReport(d.year, d.month, d.frontStoreWeightInPounds, d.backStoreWeightInPounds, d.categories, d.submitted));
        }

        return summaries;
    }

    public static async updateBranch(token: string, branchId: string, branch: API.Requests.Branch): Promise<API.Responses.Branch> {
        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(branch)
        };

        let response = await fetch(`/api/branch/${branchId}`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        return new API.Responses.Branch(
            json.id,
            json.name,
            json.email,
            json.phoneNumber,
            json.province,
            json.city,
            json.address1,
            json.address2,
            json.postalCode,
            json.lat,
            json.lng,
            json.website,
            json.operatingHours,
            json.contactName,
            json.contactTitle,
            json.contactEmail,
            json.contactPhoneNumber,
            json.numStaff,
            json.numVolunteers,
            json.numServedInPreviousYear,
            json.totalPoundsDistributedAnnually);
    }

    public static async getLastSubmissionDate(token: string, branchId: string): Promise<Date | null> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/donations/last-submission-date`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        if (response.status === 204) {
            return null;
        }

        const json = await response.json();

        if (json === null) {
            return null;
        }

        return new Date(json);
    }

    public static async getIsMonthSubmitted(token: string, branchId: string, year: number, month: number): Promise<boolean> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/${year}/${month}/submitted`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        return json;
    }

    public static async getSubmissionComments(token: string, branchId: string, year: number, month: number): Promise<string> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/${year}/${month}/comments`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let text = await response.text();
        return text.slice(1, -1);
    }
}