import { StatusMessage } from "models";

export class ForgotPasswordState {
    username: string;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    loading: boolean;


    constructor() {
        this.username = '';
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.loading = false;
    }
};