import * as React from 'react';
import { Component } from 'react';

import './body.css'

export class BodyProperties {
    includePadding?: boolean;
}

export class Body extends Component<BodyProperties, {}> {
    render() {
        let style = {};
        if (this.props.includePadding) {
            style = {padding: 16};
        }
        return (
            <main className="body" style={style}>
                {this.props.children}
            </main>
        );
    }
}