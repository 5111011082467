import { Feedback } from "models/api/requests";
import { Contact } from 'models/api/responses';
import { StatusMessage } from 'models';

export class FeedbackState {
	contacts: Contact[];
	feedback: Feedback;
	loading: boolean;
	showStatusMessage: boolean;
	statusMessage: StatusMessage;
	showFeedbackSentPopup: boolean;

	constructor() {
		this.contacts = [];
		this.feedback = new Feedback();
		this.loading = false;
		this.showStatusMessage = false;
		this.statusMessage = new StatusMessage();
		this.showFeedbackSentPopup = false;
	}
}