import { StatusMessage } from "models";
import { Campaign, Store } from "models/api/responses";
import { Donation } from "models/api/requests";

export class AddDonationState {
    stores: Store[];
    campaigns: Campaign[];
    donation: Donation;
    campaignId: string;
    loading: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    errors: ValidationErrors;

    constructor() {
        this.stores = [];
        this.campaigns = [];
        this.donation = new Donation();
        this.campaignId = "";
        this.loading = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.errors = new ValidationErrors();
    }
};

class ValidationErrors {
    store: boolean;
    campaign: boolean;
    weight: boolean;
    categorySum: boolean;
    date: boolean;

    constructor() {
        this.store = false;
        this.campaign = false;
        this.weight = false;
        this.categorySum = false;
        this.date = false;
    }
}
