import { API } from "models";
import i18n from 'i18n';

export class UserService {
    private static memoryStorage: any = {};

    public static saveUser(user: API.Responses.User) {
        try {
            sessionStorage.setItem('user', JSON.stringify(user));
        }
        catch {
            this.memoryStorage.user = user;
        }
    }

    public static removeUser(user: API.Responses.User) {
        try {
            sessionStorage.removeItem('user');
        }
        catch {
            this.memoryStorage.user = null;
        }
    }

    public static loadUser(): API.Responses.User | null {
        var o: any = null;
        try {
            var json = sessionStorage.getItem('user');
            if (json === null) {
                return null;
            }
            o = JSON.parse(json);
        }
        catch {
            if (this.memoryStorage.user) {
                o = this.memoryStorage.user;
            }
        }

        if (o === null) {
            return null;
        }
        
        const u = new API.Responses.User(o.id, o.token, o.branches, o.contactEmail, o.locale.substr(0, 2), o.workplace);
        
        return u;
    }

    public static loadMessengerExtensionsSDK(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (window.MessengerExtensions) {
                resolve();
            }
            window.extAsyncInit = function () {
                resolve();
            };
            let messengerExtensionsScript = document.createElement('script');
            messengerExtensionsScript.id = 'Messenger';
            messengerExtensionsScript.src = 'https://connect.facebook.net/en_US/messenger.Extensions.js';
            document.body.prepend(messengerExtensionsScript);
        });
    }

    public static async loginViaToken(token: any, botSource: any, localeFromCookie: any): Promise<API.Responses.User> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ token: token })
        };

        let response = await fetch(`/api/user/login/token?botSource=${botSource}`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        if (localeFromCookie) {
            i18n.changeLanguage(localeFromCookie);
        } else {
            i18n.changeLanguage(json.locale.substr(0, 2));
        }

        return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace);
    }

    public static async loginViaSSO(token: any, botSource: any, localeFromCookie: any): Promise<API.Responses.User | null> {
        try {
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ token: token, botSource: botSource })
            };

            let response = await fetch(`/api/user/login/sso`, options);

            let json = await response.json();

            if (!response.ok) {
                if (json != null) {
                    throw new Error(json.message);
                }
                else {
                    throw new Error(response.status.toString());
                }
            }

            if (localeFromCookie) {
                i18n.changeLanguage(localeFromCookie);
            } else {
                if (json.locale.substr(0, 2)) {
                    i18n.changeLanguage(json.locale.substr(0, 2));
                }
            }

            return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace);
        }
        catch (error) {
            return null;
        }
    }

    public static async loginViaWorkplaceRFP(localeFromCookie: any): Promise<API.Responses.User | null> {
        try {
            const [username, password] = await this.getFacebookToken(process.env.REACT_APP_FACEBOOK_RFP_BOT_APP_ID?.trim());
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: username, password: password, botSource: "rfp" })
            };

            let response = await fetch(`/api/user/login/facebook`, options);

            let json = await response.json();

            if (!response.ok) {
                if (json != null) {
                    throw new Error(json.message);
                }
                else {
                    throw new Error(response.status.toString());
                }
            }

            if (localeFromCookie) {
                i18n.changeLanguage(localeFromCookie);
            } else {
                if (json.locale.substr(0, 2)) {
                    i18n.changeLanguage(json.locale.substr(0, 2));
                }
            }

            return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace);
        }
        catch (error) {
            return null;
        }
    }

    public static async loginViaWorkplaceInfo(localeFromCookie: any): Promise<API.Responses.User | null> {
        try {
            const [username, password] = await this.getFacebookToken(process.env.REACT_APP_FACEBOOK_INFO_BOT_APP_ID?.trim());
            let options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username: username, password: password, botSource: "info" })
            };

            let response = await fetch(`/api/user/login/facebook`, options);

            let json = await response.json();

            if (!response.ok) {
                if (json != null) {
                    throw new Error(json.message);
                }
                else {
                    throw new Error(response.status.toString());
                }
            }

            if (localeFromCookie) {
                i18n.changeLanguage(localeFromCookie); 
            } else {
                if (json.locale.substr(0, 2)) { 
                    i18n.changeLanguage(json.locale.substr(0, 2)); 
                }
            }

            return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace);
        }
        catch (error) {
            return null;
        }
    }

    public static async login(username: string, password: string, localeFromCookie: any): Promise<API.Responses.User> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: username, password: password})
        };

        let response = await fetch(`/api/user/login`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }
        
        if (localeFromCookie) {
            i18n.changeLanguage(localeFromCookie); 
        } else {
            i18n.changeLanguage(json.locale.substr(0, 2));
        }

        return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace, json.loginType);
    }

    public static async getUserByEmail(email: string, idToken: string, localeFromCookie: any): Promise<API.Responses.User> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: idToken })
        };

        let response = await fetch(`/api/user/get-user-by-email?email=${email}`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        if (localeFromCookie) {
            i18n.changeLanguage(localeFromCookie);
        } else {
            i18n.changeLanguage(json.locale.substr(0, 2));
        }

        return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace, json.loginType);
    }

    public static async getUserByToken(objectId: string, email: string, idToken: string, localeFromCookie: any): Promise<API.Responses.User> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: idToken })
        };

        let response = await fetch(`/api/user/get-user-by-token?email=${email}&objectId=${objectId}`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        if (localeFromCookie) {
            i18n.changeLanguage(localeFromCookie);
        } else {
            i18n.changeLanguage(json.locale.substr(0, 2));
        }

        return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace, json.loginType);
    }

    public static async getBgData(): Promise<API.Responses.BgData> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let response = await fetch(`/api/user/bg-data`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        return new API.Responses.BgData(json.logo, json.black_letter_logo, json.title, json.content, json.bg_image, json.content_width, json.fr_logo, json.fr_black_letter_logo, json.fr_title, json.fr_content, json.fr_bg_image, json.fr_content_width);
    }

    public static async changePasswordVerification(token: string, oldPassword: string, newPassword: string): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({oldPassword: oldPassword, newPassword: newPassword})
        };

        let response = await fetch(`/api/user/current-user/change-password`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async forgotPassword(username: string): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({username: username})
        };

        let response = await fetch('/api/user/forgot-password', options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async loginViaEmailChallenge(username: any, challenge: any): Promise<boolean> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ })
        };

        let response = await fetch(`/api/user/login/challenge?username=${username}&code=${challenge}`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        return true;
    }

    public static async checkLoginEmailChallenge(challenge: any, botSource: string, localeFromCookie: any): Promise<API.Responses.User | null> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        };

        let response = await fetch(`/api/user/login/challenge/check?botSource=${botSource}&code=${challenge}`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        if (!json) {
            return null;
        }

        if (localeFromCookie) {
            i18n.changeLanguage(localeFromCookie);
        } else {
            if (json.locale.substr(0, 2)) {
                i18n.changeLanguage(json.locale.substr(0, 2));
            }
        }

        return new API.Responses.User(json.id, json.token, json.branches, json.contactEmail, json.locale.substr(0, 2), json.workplace);
    }
    
    private static async getFacebookToken(appId): Promise<[string, string]> {
        return new Promise((resolve, reject) => {
            setTimeout(function () { reject('timed out'); }, 5000);

            window.MessengerExtensions.getContext(appId!,
                function (thread_context) {
                    resolve([thread_context.psid, thread_context.signed_request]);
                },
                function (error) {
                    reject(error);
                }
            );
        });
    }
}