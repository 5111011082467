import * as React from 'react';
import { Component } from 'react';
import { Button, Grid, MenuItem, FormControl, Select, TextField, Paper, Typography, InputLabel, FormHelperText } from '@material-ui/core';
import i18next from 'i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import 'moment/locale/fr'; 
import { ExpandMore } from '@material-ui/icons';
import { StatusMessage } from 'models';
import { WeightUnit } from 'models/api';
import { Requests, Responses } from 'models/api';
import { BranchService, DonationService, CategoryService } from 'services';
import { Body } from 'components/body/body';
import { Footer } from 'components/footer/footer';
import Header from 'components/header/header';
import { Page } from 'components/page/page';
import { CategoryList } from 'components/donation/category-list/category-list';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { EditDonationProps } from './edit-donation-props';
import { EditDonationState } from './edit-donation-state';
import './edit-donation.css';
import { RFP_Id} from 'models/api';
import { DonationSource } from '../../../models/api/donation-source';

export class EditDonation extends Component<EditDonationProps, EditDonationState> {
    constructor(props: EditDonationProps) {
        super(props);
        this.state = new EditDonationState();
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });

            const [categories, donation, stores] = await Promise.all<Responses.Category[], Responses.Donation, Responses.Store[]>([
                CategoryService.getCategories(this.props.user.token),
                (RFP_Id === this.props.campaignId) ? DonationService.getDonation(this.props.user.token, this.props.donationId) : DonationService.getCampaignDonation(this.props.user.token, this.props.donationId, this.props.campaignId),
                (RFP_Id === this.props.campaignId) ? BranchService.getStores(this.props.user.token, this.props.user.branchId) : BranchService.getCampaignStores(this.props.user.token, this.props.user.branchId, this.props.campaignId)
            ]);

            this.setState({
                stores: stores,
                donation: new Requests.Donation(
                    donation.branchId,
                    donation.date,
                    donation.storeId,
                    donation.source,
                    donation.weight,
                    donation.weightUnit,
                    categories.map(category => {
                        return new Requests.DonationCategory(category.id, category.name, donation.categories.find(c => c.categoryId === category.id)?.fraction ?? null);
                    })
                )
            });
        } finally {
            this.setState({ loading: false });
        }
    }

    private async submit(): Promise<void> {
        await this.checkWeightValid();
        await this.checkCategorySumValid();
        var hasDateError = this.isInvalidYear(this.state.donation.date);
        var hasWeightError = this.state.errors.weight;
        var hasCategorySumError = this.state.errors.categorySum;
        if (hasWeightError || hasCategorySumError || hasDateError) {
            this.setState({ errors: { ...this.state.errors, weight: hasWeightError, date: hasDateError } });
            return;
        }

        try {
            this.setState({ loading: true });
            let donation;
            if (RFP_Id === this.props.campaignId) {
                
                donation = await DonationService.updateDonation(this.props.user.token, this.props.donationId, this.state.donation);
                await DonationService.updateDonationGHGs(this.props.user.token, [donation.id]);
            }
            else {
                
                donation = await DonationService.updateCampaignDonation(this.props.user.token, this.props.donationId, this.state.donation);
                //await DonationService.updateDonationGHGs(this.props.user.token, [donation.id]);
                await DonationService.updateCampaginDonationGHGs(this.props.user.token, [donation.id]);                
            }
            const currentLocale = this.props.user && this.props.user.locale ? this.props.user.locale : 'en';
            this.goBack(new StatusMessage(`${i18next.t("NOTE_UPDATED_DONATION")} ${donation.date.toLocaleDateString(currentLocale)}.`, 'success'));
        } catch(e) {
            if (e.message.includes('month already submitted')) {
                this.showStatusMessage(new StatusMessage(i18next.t("NOTE_ERROR_EDIT_DONATION_SUBMITTED"), 'error'));
            }
            else {
                this.showStatusMessage(new StatusMessage(i18next.t("NOTE_ERROR_EDIT_DONATION"), 'error'));
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private goBack(statusMessage: StatusMessage | null = null) {
        const from = this.props.route.location.state?.from ?? '/';
        this.props.route.history.replace(from, {
            statusMessage: statusMessage,
            year: this.props.route.location.state?.year,
            month: this.props.route.location.state?.month
        });
    }

    private updateDate(date: any) {
        this.setState({ donation: { ...this.state.donation, date: date } });
        this.setState({
            errors: { ...this.state.errors, date: this.isInvalidYear(date) }
        })
    }

    private isInvalidYear(date: any) {
        var currentDate = new Date();
        var donationDate = moment(date).toDate();
        if (currentDate.getMonth() + 1 >= 4) { // To get the correct month number, we must add 1
            return currentDate.getFullYear() > donationDate.getFullYear();
        } else {
            return currentDate.getFullYear() > (donationDate.getFullYear() + 1);
        }
    }

    private updateStoreId(storeId: string) {
        this.setState({ donation: { ...this.state.donation, storeId: storeId } })
    }

    private updateSource(source: DonationSource) {
        this.setState({
            donation: { ...this.state.donation, source: source }
        });
    }

    private updateWeight(weight: number) {
        this.setState({
            donation: { ...this.state.donation, weight: weight }
        }, () => this.checkWeightValid())
    }

    private checkWeightValid() {
        if (!isNaN(this.state.donation.weight) && this.state.donation.weight >= 0) {
            this.setState({
                errors: { ...this.state.errors, weight: false }
            });
        }
        else {
            this.setState({
                errors: { ...this.state.errors, weight: true }
            });
        }
    }

    private updateWeightUnit(weightUnit: WeightUnit) {
        this.setState({
            donation: { ...this.state.donation, weightUnit: weightUnit }
        })
    }

    private updateCategory(category: Requests.DonationCategory, fraction: number | null) {
        this.setState({
            donation: {
                ...this.state.donation,
                categories: this.state.donation.categories.map(c => c === category ? { ...c, fraction: fraction } : c)
            }
        }, () => this.checkCategorySumValid());
    }

    private checkCategorySumValid() {
        var categorySum = this.state.donation.categories.reduce((a, b) => {
            return (a + (b.fraction == null || isNaN(b.fraction) ? 0 : b.fraction * 100))
        }, 0);

        if (categorySum === 100) {
            this.setState({
                errors: { ...this.state.errors, categorySum: false }
            });
        }
        else {
            this.setState({
                errors: { ...this.state.errors, categorySum: true }
            });
        }
    }

    private generateFullStoreName(s: Responses.Store) {
        return `${s.retailer?.name != null ? s.retailer?.name + ' - ' : ''}${s.banner?.name != null ? s.banner?.name + ' - ' : ''}${s.name}`;
    }

    render() {
        moment.locale(this.props.user && this.props.user.locale ? this.props.user.locale : 'en');
        const { errors } = this.state;
        return (
            <Page>
                <Header 
                    route={this.props.route} 
                    title={i18next.t('EDIT_DONATION')} 
                    showHamburgerButton={false}
                    />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar/>
                            :
                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={(this.props.user && this.props.user.locale ? this.props.user.locale : 'en')}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="MM/DD/yyyy"
                                            label={i18next.t('LABEL_DATE')}
                                            value={this.state.donation.date}
                                            onChange={(e) => this.updateDate(e)}
                                            invalidDateMessage={i18next.t('VALIDATION_DATE')}
                                            fullWidth
                                            disableToolbar
                                            className={errors.date ? 'date-validation' : ''}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.date ? <FormHelperText className="MuiFormHelperText-contained Mui-error">{i18next.t('VALIDATION_DATE_PREVIOUS_YEAR')}</FormHelperText> : null}
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="edit-donation-store-label">{i18next.t('LABEL_STORE')}</InputLabel>
                                        <Select
                                            labelId="edit-donation-store-label"
                                            label={i18next.t('LABEL_STORE')}
                                            value={this.state.donation.storeId}
                                            onChange={(e: any) => this.updateStoreId(e.target.value)}>
                                            {
                                                this.state.stores.map((s: any) => <MenuItem key={s.id} value={s.id}>{this.generateFullStoreName(s)}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="edit-donation-source-label">{i18next.t('LABEL_SOURCE')}</InputLabel>
                                        <Select
                                            labelId="edit-donation-source-label"
                                            label={i18next.t('LABEL_SOURCE')}
                                            value={this.state.donation.source}
                                            onChange={(e: any) => this.updateSource(e.target.value as DonationSource)}>
                                            <MenuItem value={DonationSource.FrontStore}>{i18next.t('LABEL_FRONT_STORE')}</MenuItem>
                                            <MenuItem value={DonationSource.BackStore}>{i18next.t('LABEL_BACK_STORE')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        variant="outlined"
                                        error={errors.weight}
                                        type="number"
                                        label={i18next.t('LABEL_WEIGHT')}
                                        value={isNaN(this.state.donation.weight) ? "" : this.state.donation.weight}
                                        onChange={(e) => this.updateWeight(parseFloat(e.target.value))}
                                        inputProps={{ min: "0", step: "any" }}
                                        helperText={errors.weight ? i18next.t('VALIDATION_WEIGHT')  : ""}
                                        fullWidth />
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="edit-donation-weight-unit-label">{i18next.t('LABEL_UNIT')}</InputLabel>
                                        <Select
                                            labelId="edit-donation-weight-unit-label"
                                            label={i18next.t('LABEL_UNIT')}
                                            value={this.state.donation.weightUnit}
                                            onChange={(e: any) => this.updateWeightUnit(e.target.value as WeightUnit)}>
                                            <MenuItem value={WeightUnit.Pounds}>{i18next.t('LABEL_POUNDS')} (lbs)</MenuItem>
                                            <MenuItem value={WeightUnit.Kilogram}>{i18next.t('LABEL_KILOGRAMS')} (kg)</MenuItem>
                                            <MenuItem value={WeightUnit.BananaBox}>{i18next.t('LABEL_BANANA_BOX')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Paper>
                                        <Typography className="donation-categories-header" variant="subtitle1" color={errors.categorySum ? "error" : "inherit"}>
                                            {i18next.t('LABEL_CATEGORIES')}
                                        </Typography>
                                        <Typography className="donation-categories-desc">
                                            {i18next.t('LABEL_CATEGORIES_DESC')}
                                        </Typography>
                                        <div className="donation-categories">
                                            <CategoryList categories={this.state.donation.categories} updateCategory={(categoryIndex, fraction) => this.updateCategory(categoryIndex, fraction)} hasError={errors.categorySum} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                    }
                </Body>
                <Footer>
                    <Button variant="contained" disableElevation fullWidth color="primary" onClick={(e: any) => this.submit()} disabled={this.state.loading}>
                        {i18next.t('BUTTON_SAVE')}
                    </Button>
                    <Button variant="contained" disableElevation fullWidth color="secondary" onClick={(e: any) => this.goBack()}>
                        {i18next.t('BUTTON_CANCEL')}
                    </Button>
                </Footer>
            </Page>
        );
    }
}