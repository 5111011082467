import DateFnsUtils from '@date-io/date-fns';
import * as React from 'react';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import {
    ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { User, UserLoginType } from 'models/api/responses';
import { UserService } from './services';
import Login from './components/login/login';
import {
    AddDonation,
    DonationsList,
    EditDonation,
    MonthlyReports
} from 'components/donation';
import { PrivateRoute } from 'components/private-route/private-route';
import { UpdateBranchDetails } from './components/branch';
import { ForgotPassword } from './components/forgot-password';
import { Feedback } from './components/feedback/feedback';
import { ChangePassword } from './components/change-password';
import { AppState } from 'AppState';
import { theme } from 'theme';
import i18next from 'i18next';
import { withCookies } from 'react-cookie';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { MsalContext } from "@azure/msal-react";
import { Body } from 'components/body/body';
import { Page } from 'components/page/page';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import './App.css';
import Layout from './Layout';

class MainContent extends Component<any, AppState> {
    static contextType = MsalContext;

    constructor(props: any) {
        super(props);
        this.state = new AppState(UserService.loadUser());
    }

    componentDidMount() { // initialize language from cookies if it have language in cookies saved
        if (this.props.cookies && this.props.cookies.get('fbc-locale')) {

            let cookieLocale = this.props.cookies.get('fbc-locale') === 'fr' ? 'fr' : 'en';

            i18next.changeLanguage(cookieLocale).then((t) => {
                this.setState({ siteLocale: cookieLocale });
            });
        } else if (this.state.user) { // use user locale if no language cookie found

            let userLocale = this.state.user.locale === 'fr' ? 'fr' : 'en';

            i18next.changeLanguage(userLocale).then((t) => {
                this.setState({ siteLocale: userLocale });
            });
        }

        if (this.props.user != null) {
            this.setState({ user: this.props.user }, () => UserService.saveUser(this.props.user!));
        }
    }

    private onLogin(user: User) {
        this.setState({ user: user, siteLocale: this.props.cookies.get('fbc-locale') || user.locale }, () => UserService.saveUser(this.state.user!));
    }

    private onLogout(user: User) {
        // set state
        this.setState({ user: null }, () => {
            // remove session storage
            UserService.removeUser(user);
            // remove language cookie
            this.props.cookies.remove('fbc-locale', { path: '/', sameSite: 'none', secure: true });

            sessionStorage.removeItem('aad-login-loading');
            if (user.loginType === UserLoginType.AAD) {
                this.props.aadInstance.logoutRedirect();
            } else if (user.loginType === UserLoginType.AADB2C) {
                this.props.aadB2CInstance.logoutRedirect();
            }
        });
    }

    private onBranchSelected(branchId: string) {
        this.setState({ user: { ...this.state.user!, branchId: branchId } }, () => UserService.saveUser(this.state.user!));
    }

    private handleLangChange(selectedLocale: string) {
        i18next.changeLanguage(selectedLocale).then((t) => {
            this.setState({ siteLocale: selectedLocale });
            this.props.cookies.set('fbc-locale', selectedLocale, { path: '/', sameSite: 'none', secure: true });
        });
    }

    render() {
        var authenticated = (this.state.user !== null || this.props.user != null);

        var user = this.state.user!;
        if (this.props.user != null && this.state.user == null) { // just after login is done
            user = this.props.user
        }

        return (<>
            {this.props.showEmptyPageForFacebookUser && this.props.fromWorkplace ?
                <div className="facebook-blank-page">
                    {this.props.messageForFacebookUser}
                    <br />
                    <br />
                    {this.props.urlForFacebookUser}
                </div>
                :
                <Layout cookies={this.props.cookies} user={user} siteLocale={this.state.siteLocale} handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)} fromWorkplace={this.props.fromWorkplace}>
                    <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Router>
                                <Route render={({ location }) => (
                                    <Switch location={location}>
                                        <Route exact path='/login' render={(props) => (
                                            <Login
                                                onLogin={(user) => this.onLogin(user)}
                                                route={props}
                                                aadInstance={this.props.aadInstance}
                                                aadB2CInstance={this.props.aadB2CInstance}
                                                aadLoginRequest={this.props.aadLoginRequest}
                                                aadB2CLoginRequest={this.props.aadB2CLoginRequest}
                                                fromWorkplace={this.props.fromWorkplace}
                                                twoFactorErrorMessage={""}
                                            />
                                        )} />
                                        <Route exact path='/forgot-password' render={(props) => (
                                            <ForgotPassword
                                                route={props}
                                            />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/' render={(props) => {
                                            return <MonthlyReports
                                                route={props}
                                                user={user}
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                                siteLocale={this.state.siteLocale}
                                                handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)}
                                            />
                                        }
                                        } />
                                        <PrivateRoute authenticated={authenticated} exact path='/branch' render={(props) => (
                                            <UpdateBranchDetails
                                                route={props}
                                                user={user}
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                                siteLocale={this.state.siteLocale}
                                                handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)} />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/donations/add' render={(props) => (
                                            <AddDonation
                                                route={props}
                                                user={user}
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                                siteLocale={this.state.siteLocale}
                                                handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)} />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/donations/:year/:month' render={(props) => (
                                            <DonationsList
                                                route={props}
                                                user={user}
                                                year={props.match.params['year']}
                                                month={props.match.params['month']}
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                                siteLocale={this.state.siteLocale}
                                                handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)} />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/donations/edit/:id/:camapaignid' render={(props) => (
                                            <EditDonation
                                                route={props}
                                                user={user}
                                                donationId={props.match.params['id']}
                                                campaignId={props.match.params['camapaignid']}                                                                     
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                            />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/feedback' render={(props) => (
                                            <Feedback
                                                route={props}
                                                user={user}
                                            />
                                        )} />
                                        <PrivateRoute authenticated={authenticated} exact path='/change-password' render={(props) => (
                                            <ChangePassword
                                                route={props}
                                                user={user}
                                                onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                                                onLogout={(user) => this.onLogout(user)}
                                                siteLocale={this.state.siteLocale}
                                                handleLangChange={(selectedLocale) => this.handleLangChange(selectedLocale)}
                                            />
                                        )} />
                                        <Route>
                                            <Redirect to="/login" />
                                        </Route>
                                    </Switch>
                                )} />
                            </Router>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </Layout>
            }
        </>);
    }
}

const WrapMainContent = (props) => {
    const {accounts} = useMsal();
    const [user, setUser] = React.useState<User | null>(null);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const loadUser = UserService.loadUser();

    React.useEffect(() => {
        const getUserByToken = async (email, objectId, token): Promise<void> => {
            try {
                const user = await UserService.getUserByToken(email, objectId, token, props.cookies.get('fbc-locale'));
                setUser(user);
            } catch (e) {
                setErrorMessage(e.message);
            }
        }

        const getUserByEmail = async (email, token): Promise<void> => {
            try {
                const user = await UserService.getUserByEmail(email, token, props.cookies.get('fbc-locale'));
                setUser(user);
            } catch (e) {
                setErrorMessage(e.message);
            }
        }

        //if User data is not in the Session or Cookie && AAD auth exists
        const getToken = async (): Promise<void> => {
            if (accounts.length > 0) {
                console.log('accounts', accounts);
                if (accounts[0].environment === 'login.windows.net') {
                    var token = await props.aadInstance
                        .acquireTokenSilent({
                            scopes: [],
                            account: accounts[0],
                        });
                }
                else {
                    var token = await props.aadB2CInstance
                        .acquireTokenSilent({
                            scopes: [],
                            account: accounts[0]
                        });
                }

                if (loadUser == null && accounts[0].idTokenClaims && accounts[0] && token.idToken) {
                    if (accounts[0].idTokenClaims.emails != null) { // AAD B2C user has email
                        getUserByEmail((accounts[0].idTokenClaims as any).emails[0], token.idToken);
                    }
                    else {
                        getUserByToken(accounts[0].idTokenClaims.oid, accounts[0].username, token.idToken);
                    }
                }
            }
        }
        getToken();
    }, [])

    const displayPage = (props) => {
        if (loadUser != null || user != null) {
            return <MainContent user={user} {...props} />

        } else if (errorMessage) {
            return <ThemeProvider theme={theme}>
                <Layout cookies={props.cookies} siteLocale={'en'} twoFactorErrorMessage={errorMessage}>
                    <Login
                        onLogin={() => { }}
                        route={null}
                        twoFactorErrorMessage={errorMessage}
                        aadInstance={props.aadInstance}
                        aadB2CInstance={props.aadB2CInstance}
                        aadLoginRequest={props.aadLoginRequest}
                        aadB2CLoginRequest={props.aadB2CLoginRequest}
                        fromWorkplace={false}
                    />
                </Layout>
            </ThemeProvider>
        } else {
            return <ThemeProvider theme={theme}>
                <Page>
                    <Body>
                        <LoadingBar />
                    </Body>
                </Page>
            </ThemeProvider>
        }
    }

    return <>
        {displayPage(props)}
    </>
};

const Authentication = (props) => {
    return (
        <>
            <UnauthenticatedTemplate>
                {
                    sessionStorage.getItem('aad-login-loading') ?
                        <ThemeProvider theme={theme}>
                            <Page>
                                <Body>
                                    <LoadingBar />
                                </Body>
                            </Page>
                        </ThemeProvider>
                        :
                        <MainContent {...props} />
                }
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <WrapMainContent {...props} />
            </AuthenticatedTemplate>
        </>
    );
};

const App = (props) => {
    const queryParams = new URLSearchParams(window.location.search);
    const ssoLogin = queryParams.get('sso');
    const fromWorkplace = queryParams.get('from') === "workplace" || ssoLogin;
    
    const display = () => {
        if (fromWorkplace) {
            return <MainContent fromWorkplace={fromWorkplace} {...props} />
        } else {
            return <MsalProvider instance={props.aadInstance}>
                <MsalProvider instance={props.aadB2CInstance}>
                    <Authentication {...props} />
                </MsalProvider>
            </MsalProvider>
        }
    }

    return <>
        {
            display()
        }
    </>
}

export default withCookies(App);