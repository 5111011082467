import * as React from 'react';
import { Component } from "react";

import { Slide } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { StatusBarProps } from "./status-bar-props";
import { StatusBarState } from "./status-bar-state";

export class StatusBar extends Component<StatusBarProps, StatusBarState> {
	render() {
		return (
			<Slide direction="right" in={this.props.show} timeout={0} mountOnEnter unmountOnExit>
				<Alert severity={this.props.message.severity} onClose={() => this.props.onClose()}>
					{this.props.message.text}
				</Alert>
			</Slide>
		);
	}
}