export class Contact {
	id: string;
	name: string;
	emailAddress: string;

	constructor(id: string, name: string, emailAddress: string) {
		this.id = id;
		this.name = name;
		this.emailAddress = emailAddress;
	}
}