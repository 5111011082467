import * as React from 'react';
import { Component } from 'react';
import { Button, Grid, OutlinedInput } from '@material-ui/core';
import { StatusMessage } from 'models';
import { UserService } from 'services';
import { Body } from 'components/body/body';
import { Footer } from 'components/footer/footer';
import Header from 'components/header/header';
import { Page } from 'components/page/page'
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { ForgotPasswordProps } from './forgot-password-props';
import { ForgotPasswordState } from './forgot-password-state';

export class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
    constructor(props: ForgotPasswordProps) {
        super(props);
        this.state = new ForgotPasswordState();
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private async sendEmail() {
        if (!this.isValidUsername()) {
            return;
        }

        try {

            this.setState({ loading: true });
            this.hideStatusMessage();
            await UserService.forgotPassword(this.state.username);
            this.showStatusMessage(new StatusMessage('The email containing a new password has been sent to your email address.', 'success'));

        } catch(error) {

            if (error.message.includes('404')) { // user not found
                this.showStatusMessage(new StatusMessage('Invalid username.', 'error'));
            } else if (error.message.includes('401')) { // not type 3 user (non-affiliates)
                this.showStatusMessage(new StatusMessage('You are not allowed to reset your password. Please contact your administrator for more information.', 'error'));
            } else if (error.message.includes('400')) { // invalid email format
                this.showStatusMessage(new StatusMessage("Invalid Email Format", 'error'));
            } else {
                this.showStatusMessage(new StatusMessage('Error sending reset password email. Please try again shortly.', 'error'));
            }

        } finally {
            this.setState({ loading: false });
        }
    }

    private goToLogin() {
        this.props.route.history.replace('/login');
    }

    private isValidUsername() {
        return this.state.username !== undefined && this.state.username !== null && this.state.username !== '';
    }

    private updateUsername(value: string) {
        this.setState({ username: value });
    }

    render() {
        return (
            <Page>
                <Header 
                    route={this.props.route} 
                    title="Forgot Password" 
                    showHamburgerButton={false}
                />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar />
                            :
                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <OutlinedInput
                                        required
                                        placeholder="Username"
                                        value={this.state.username}
                                        onChange={(e: any) => this.updateUsername(e.target.value)} 
                                        fullWidth 
                                    />
                                </Grid>
                            </Grid>
                    }
                </Body>
                <Footer>
                    <Button variant="contained" disableElevation fullWidth color="primary" disabled={!this.isValidUsername()} onClick={(e: any) => this.sendEmail()}>
                        Send Email
                    </Button>

                    <Button variant="contained" disableElevation fullWidth color="primary" onClick={(e: any) => this.goToLogin()}>
                        Back To Login
                    </Button>
                </Footer>
            </Page>
        );
    }
}