import * as React from 'react';
import { Component } from 'react';
import { Grid } from '@material-ui/core';

import './footer.css'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer">
                <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                    {
                        React.Children.map(this.props.children, (child, i) => {

                            return (
                                <Grid item xs={12}>
                                    {child}
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </footer>
        );
    }
}