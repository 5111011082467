import { StatusMessage } from "models";

export class LoginState {
    username: string;
    password: string;
    showPassword: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;    
    redirectToReferrer: boolean;
    loading: boolean;
    loginViaWorkplace: boolean;
    showSSOInstruction: boolean;
    loginViaEmail: boolean;
    checking: boolean;

    constructor() {
        this.username = '';
        this.password = '';
        this.showPassword = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();        
        this.redirectToReferrer = false;
        this.loading = false;
        this.loginViaWorkplace = false;
        this.showSSOInstruction = false;
        this.loginViaEmail = false;
        this.checking = false;
    }
};