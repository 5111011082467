import { Store } from 'models/api/responses';
import { Donation } from 'models/api/requests';
import { StatusMessage } from 'models';

export class EditDonationState {
    stores: Store[];
    donation: Donation;
    loading: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    errors: ValidationErrors;

    constructor() {
        this.stores = [];
        this.donation = new Donation();
        this.loading = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.errors = new ValidationErrors();
    }
};

class ValidationErrors {
    weight: boolean;
    categorySum: boolean;
    date: boolean;

    constructor() {
        this.weight = false;
        this.categorySum = false;
        this.date = false;
    }
}
