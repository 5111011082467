import { API } from "models";
import { DonationCategory } from "models/api/responses/donation-category";

export class DonationService {
    public static async getDonationsByBranchAndYear(token: string, branchId: string, year: number): Promise<API.Responses.Donation[]> {
        var startDate = new Date(Date.UTC(year, 0)).toUTCString();
        var endDate = new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999)).toUTCString();
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response = await fetch(`/api/donation/paged?appSource=bot&branchId=${branchId}&startDate=${startDate}&endDate=${endDate}&orderBy=date&order=asc`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();
        let donations: API.Responses.Donation[] = [];

        for (let d of json.entities) {
            donations.push(new API.Responses.Donation(d.id, d.branchId, new Date(d.date + 'Z'), d.storeId, d.store, d.source, d.weightInPounds, d.weight, d.weightUnit, d.categories, d.submitted, d.submitDate));
        }

        return donations;
    }

    public static async getDonation(token: string, id: string): Promise<API.Responses.Donation> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response;
        response= await fetch(`/api/donation/${id}`, options);

        if (!response.ok) {            
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        return new API.Responses.Donation(json.id, json.branchId, new Date(json.date + 'Z'), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, json.categories, json.submitted, json.submitDate);
    }

    public static async getCampaignDonation(token: string, id: string, campaignId: string): Promise<API.Responses.Donation> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response;
        response = await fetch(`/api/donation/campaigndonation/${id}`, options);
        
        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();
        
        let dcs = json.campaignCategories.map(c => new DonationCategory(c.id, c.category.name, c.categoryId, c.category, c.fraction));
        var resultDonation = new API.Responses.Donation(json.id, json.branchId, new Date(json.date + 'Z'), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, dcs/*json.campaigncategories*/, json.submitted, json.submitDate);
        return resultDonation;
    }

    public static async getDonationYears(token: string): Promise<number[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response = await fetch(`/api/donation/donationyears?appSource=bot`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        return json;
    }

    public static async updateDonation(token: string, id: string, donation: API.Requests.Donation): Promise<API.Responses.Donation> {
        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donation)
        };

        let response = await fetch(`/api/donation/${id}`, options);

        if (!response.ok) {
            if (response.status === 400) {
                let errorJson = await response.json();
                throw new Error(errorJson.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        let json = await response.json();
        return new API.Responses.Donation(json.id, json.branchId, new Date(json.date), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, json.categories, json.submitted, json.submitDate);
    }

    public static async updateCampaignDonation(token: string, id: string, donation: API.Requests.Donation): Promise<API.Responses.Donation> {
        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donation)
        };

        let response = await fetch(`/api/donation/campaigndonation/${id}`, options);

        if (!response.ok) {
            if (response.status === 400) {
                let errorJson = await response.json();
                throw new Error(errorJson.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        let json = await response.json();
        return new API.Responses.Donation(json.id, json.branchId, new Date(json.date), json.storeId, json.store, json.source, json.weightInPounds, json.weight, json.weightUnit, json.categories, json.submitted, json.submitDate);
    }

    public static async deleteDonation(token: string, id: string): Promise<void> {
        let options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        let response = await fetch(`/api/donation/${id}`, options);
        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async deleteCampaignDonation(token: string, id: string): Promise<void> {
        let options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };

        let response = await fetch(`/api/donation/campaigndonation/${id}`, options);
        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async submitDonations(token: string, branchId: string, year: number, month: number, submissionComments: string, userContact: string): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                branchId: branchId,
                year: Number(year),
                month: Number(month),
                comments: submissionComments,
                userContact: userContact
            })
        };

        let response = await fetch(`/api/branch/submit`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async updateDonationGHGs(token: string, donationIds: string[] ): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donationIds)
        };

        let response = await fetch(`/api/donation/update-ghg`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }

    public static async updateCampaginDonationGHGs(token: string, donationIds: string[]): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(donationIds)
        };

        let response = await fetch(`/api/donation/update-campaign-ghg`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
    }
}