import { createMuiTheme } from "@material-ui/core";

const fontFamily: string = ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(',');

export const theme = createMuiTheme({
    shape: {
        borderRadius: 0
    },
    palette: {
        primary: {
            main: '#fcc737'
        },
        secondary: {
            main: '#bebfc0'
        }
    },
    typography: {
        htmlFontSize: 14,
        fontFamily: fontFamily,
        fontSize: 14,
        button: {
            fontWeight: 700,
            textTransform: 'capitalize'
        },
        body1: {
            fontSize: 15
        }
    },
    overrides: {
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: '#000000'
            },
            input: {
                padding: '15.5px 14px'
            }
        },
        MuiSlider: {
            root: {
                height: 8
            },
            thumb: {
                height: 24,
                width: 24,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                marginTop: -8,
                marginLeft: -12,
            },
            valueLabel: {
                left: 'calc(-50% + 4px)'
            },
            track: {
                height: 8,
                borderRadius: 4
            },
            rail: {
                height: 8,
                borderRadius: 4
            }
        },
        MuiMenuItem: {
            root: {
                fontSize: 14,
                whiteSpace: 'normal'
            }
        }
    }
});