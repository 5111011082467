import * as React from 'react';
import { Component } from 'react';
import i18next from 'i18next'; 
import {
    Box,
    Typography
} from '@material-ui/core';
import './date-box.css'

export interface DateBoxProperties {
    month: number;
    day?: number;
}

const shortMonthNames: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export class DateBox extends Component<DateBoxProperties, {}> {
    render() {
        const month = shortMonthNames[this.props.month];

        if (this.props.day) {
            let day = String(this.props.day).padStart(2, '0');
            return (
                <Box className="date-box">
                    <Box className="month-box">
                        <Typography variant="h6">
                            <b>{i18next.t("MONTH_" + month.toUpperCase())}</b>
                        </Typography>
                    </Box>
                    <Box className="day-box">
                        <Typography variant="h4">{day}</Typography>
                    </Box>
                </Box>
            );
        } else {
            return (
                <Box className="date-box">
                    <Box className="month-box">
                        <Typography variant="h5">
                            <b>{i18next.t("MONTH_" + month.toUpperCase())}</b>
                        </Typography>
                    </Box>
                </Box>
            );
        }
    }
}