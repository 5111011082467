import * as React from 'react';
import { Component } from 'react';
import { Button, Grid, MenuItem, FormControl, Select, TextField, Paper, Typography, InputLabel, FormHelperText } from '@material-ui/core';
import i18next from 'i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import 'moment/locale/fr'; 
import { ExpandMore } from '@material-ui/icons';
import { StatusMessage } from 'models';
import { WeightUnit, RFP_Id } from 'models/api';
import { DonationCategory } from 'models/api/requests';
import { Campaign, Category, Store } from 'models/api/responses';
import { CampaignService, BranchService, CategoryService, DonationService } from 'services';
import { Body } from 'components/body/body';
import { Footer } from 'components/footer/footer';
import Header from 'components/header/header';
import { Page } from 'components/page/page';
import { CategoryList } from 'components/donation/category-list/category-list';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { AddDonationProps } from './add-donation-props';
import { AddDonationState } from './add-donation-state';
import './add-donation.css';
import { DonationSource } from '../../../models/api/donation-source';

export class AddDonation extends Component<AddDonationProps, AddDonationState> {
    constructor(props: AddDonationProps) {
        super(props);
        this.state = new AddDonationState();
    }

    async componentDidMount() {
        
        try {
            this.setState({ loading: true });            
            const [categories, stores, campaigns] = await Promise.all<Category[], Store[], Campaign[]>([
                CategoryService.getCategories(this.props.user.token),
                BranchService.getStores(this.props.user.token, this.props.user.branchId),
                CampaignService.getCampaigns(this.props.user.token, this.props.user.branchId)                
            ]);

            this.setState({
                donation: {
                    ...this.state.donation,
                    branchId: this.props.user.branchId,                    
                    storeId: '',
                    date: new Date(new Date().setHours(0, 0, 0, 0)), 
                    categories: categories.map(c => new DonationCategory(c.id, c.name, null)),
                    createdBy: this.props.user.contactEmail
                },
                campaignId: campaigns[0].id || '',
                stores: stores,
                campaigns: campaigns,
            });            
            
        } finally {
            this.setState({ loading: false });
        }
    }

    async componentDidUpdate(prevProps: AddDonationProps, prevState: AddDonationState) {
        if (prevProps.user.branchId !== this.props.user.branchId) {
            try {
                this.setState({ loading: true });
                const campaigns = await CampaignService.getCampaigns(this.props.user.token, this.props.user.branchId);
                const stores = await BranchService.getStores(this.props.user.token, this.props.user.branchId);
                
                this.setState({ donation: { ...this.state.donation, storeId: '' }, stores: stores, campaignId: campaigns[0].id || '', campaigns: campaigns });
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    private async submit(): Promise<void> {
        await this.checkWeightValid();
        var hasStoreError = this.state.donation.storeId === '';
        var hasWeightError = this.state.errors.weight;
        var hasDateError = this.isInvalidYear(this.state.donation.date);

        await this.checkCategorySumValid();
        var hasCategorySumError = this.state.errors.categorySum;
        if (hasStoreError || hasWeightError || hasCategorySumError || hasDateError) {
            this.setState({ errors: { ...this.state.errors, store: hasStoreError, weight: hasWeightError, date: hasDateError } });
            return;
        }

        try {
            this.setState({ loading: true });
            
            var donation;
            
            if (RFP_Id === this.state.campaignId) {
                // adding donation                
                donation = await BranchService.insertDonation(this.props.user.token, this.props.user.branchId, this.state.donation);
                await DonationService.updateDonationGHGs(this.props.user.token, [donation.id]);
            }
            else {
                donation = await BranchService.insertCampaignDonation(this.props.user.token, this.state.campaignId, this.props.user.branchId, this.state.donation);                
                await DonationService.updateCampaginDonationGHGs(this.props.user.token, [donation.id]);
            }
            
            const currentLocale = this.props.user && this.props.user.locale ? this.props.user.locale : 'en';
            var donationStore = this.state.stores.find(s => s.id === this.state.donation.storeId);
            var fullStoreName = (donationStore != null ? this.generateFullStoreName(donationStore) : '');
            this.goBack(new StatusMessage(`${donation.date.toLocaleDateString(currentLocale)}: ${donation.weightInPounds} lbs. ${i18next.t("NOTE_ADDED_DONATION")} ${fullStoreName}.`, 'success'));
            
        } catch (e) {
            if (e.message.includes('month already submitted')) {
                this.showStatusMessage(new StatusMessage(i18next.t("NOTE_ERROR_ADD_DONATION_SUBMITTED"), 'error'));
            }
            else {
                this.showStatusMessage(new StatusMessage(i18next.t("NOTE_ERROR_ADD_DONATION"), 'error'));
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    private goBack(statusMessage: StatusMessage | null = null) {
        const pathname = this.props.route.location.state?.from ?? '/';
        this.props.route.history.replace(pathname, {
            statusMessage: statusMessage,
            year: this.props.route.location.state?.year,
            month: this.props.route.location.state?.month
        });
    }

    private updateDate(date: any) {
        this.setState({ donation: { ...this.state.donation, date: date } });
        this.setState({
            errors: { ...this.state.errors, date: this.isInvalidYear(date) }
        })
    }

    private isInvalidYear(date: any) {
        var currentDate = new Date();
        var donationDate = moment(date).toDate();
        if (currentDate.getMonth() + 1 >= 4) { // To get the correct month number, we must add 1
            return currentDate.getFullYear() > donationDate.getFullYear();
        } else {
            return currentDate.getFullYear() > (donationDate.getFullYear() + 1);
        }
    }

    private async updateCampaignId(campaignId: string) {
        var stores;
        var source = DonationSource.BackStore;
        if (RFP_Id == campaignId)
        {
            stores = await BranchService.getStores(this.props.user.token, this.props.user.branchId);
        }
        else
        {
            stores = await BranchService.getCampaignStores(this.props.user.token, this.props.user.branchId, campaignId);
            source = DonationSource.FrontStore;
        }
        
        this.setState({
            campaignId: campaignId,
            stores: stores,
            donation: { ...this.state.donation, source: source },
            errors: { ...this.state.errors, campaign: false, store: false }
        })        
    }

    private updateStoreId(storeId: string) {
        this.setState({
            donation: { ...this.state.donation, storeId: storeId },
            errors: { ...this.state.errors, store: false}
        })
    }

    private updateSource(source: DonationSource) {
        this.setState({
            donation: { ...this.state.donation, source: source }
        });
    }

    private updateWeight(weight: number) {
        this.setState({
            donation: { ...this.state.donation, weight: weight }
        }, () => this.checkWeightValid());
    }

    private checkWeightValid() {
        if (!isNaN(this.state.donation.weight) && this.state.donation.weight >= 0) {
            this.setState({
                errors: { ...this.state.errors, weight: false }
            });
        }
        else {
            this.setState({
                errors: { ...this.state.errors, weight: true }
            });
        }
    }

    private updateWeightUnit(weightUnit: WeightUnit) {
        this.setState({
            donation: { ...this.state.donation, weightUnit: weightUnit }
        });
    }

    private updateCategory(category: DonationCategory, fraction: number | null) {
        this.setState({
            donation: {
                ...this.state.donation,
                categories: this.state.donation.categories.map(c => c === category ? { ...c, fraction: fraction } : c)
            }
        }, () => this.checkCategorySumValid());
    }

    private checkCategorySumValid() {
        var categorySum = this.state.donation.categories.reduce((a, b) => {
            return (a + (b.fraction == null || isNaN(b.fraction) ? 0 : b.fraction * 100))
        }, 0);
        if (Math.ceil(categorySum) === 100) {
            this.setState({
                errors: { ...this.state.errors, categorySum: false}
            });
        }
        else {
            this.setState({
                errors: { ...this.state.errors, categorySum: true }
            });
        }
    }

    private onBranchSelected(branchId) {
        this.props.onBranchSelected(branchId);
        this.hideStatusMessage();
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private generateFullStoreName(s: Store) {
        return `${s.retailer?.name != null ? s.retailer?.name + ' - ' : ''}${s.banner?.name != null ? s.banner?.name + ' - ' : ''}${s.name}`;
    }

    private generateFullCampaignName(s: Campaign) {
        return `${s.name}`;
    }

    render() {
        moment.locale(this.props.user && this.props.user.locale ? this.props.user.locale : 'en');
        const { errors } = this.state;
        return (
            <Page>
                <Header
                    route={this.props.route}
                    title={i18next.t('ADD_DONATION')}
                    branches={this.props.user.branches}
                    branchId={this.props.user.branchId}
                    onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                    user={this.props.user}
                    onLogout={(user) => this.props.onLogout(user)}
                    siteLocale={this.props.siteLocale}
                    handleLangChange={(selectedLocale) => this.props.handleLangChange(selectedLocale)} />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar />
                            :
                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                <Grid item>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={(this.props.user && this.props.user.locale ? this.props.user.locale : 'en')}>
                                        <KeyboardDatePicker
                                            autoOk
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="MM/DD/yyyy"
                                            label={i18next.t('LABEL_DATE')}
                                            value={this.state.donation.date}
                                            onChange={(e) => this.updateDate(e)}
                                            invalidDateMessage={i18next.t('VALIDATION_DATE')}
                                            fullWidth
                                            disableToolbar
                                            className={errors.date ? 'date-validation' : ''}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {errors.date ? <FormHelperText className="MuiFormHelperText-contained Mui-error">{i18next.t('VALIDATION_DATE_PREVIOUS_YEAR')}</FormHelperText> : null}
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth error={errors.campaign}>
                                        <InputLabel htmlFor="add-donation-campaign-label">{i18next.t('LABEL_PROGRAM')}</InputLabel>
                                        <Select value={this.state.campaignId} displayEmpty
                                            labelId="add-donation-campaign-label"
                                            label={i18next.t('LABEL_PROGRAM')}                                            
                                            onChange={(e: any) => this.updateCampaignId(e.target.value)}>
                                            {
                                                this.state.campaigns.map((s: any) => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>)
                                            }
                                        </Select>
                                        {errors.campaign ? <FormHelperText>{i18next.t('VALIDATION_SELECT_STORE')}</FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth error={errors.store}>
                                        <InputLabel htmlFor="add-donation-store-label">{i18next.t('LABEL_STORE')}</InputLabel>
                                        <Select value={this.state.donation.storeId} displayEmpty
                                            labelId="add-donation-store-label"
                                            label={i18next.t('LABEL_STORE')}
                                            onChange={(e: any) => this.updateStoreId(e.target.value)}>
                                            {
                                                this.state.stores.map((s: any) => <MenuItem key={s.id} value={s.id}>{this.generateFullStoreName(s)}</MenuItem>)
                                            }
                                        </Select>
                                        {errors.store ? <FormHelperText>{i18next.t('VALIDATION_SELECT_STORE')}</FormHelperText> : null}
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="add-donation-source-label">{i18next.t('LABEL_SOURCE')}</InputLabel>
                                        <Select value={this.state.donation.source}
                                            labelId="add-donation-source-label"
                                            label={i18next.t('LABEL_SOURCE')}
                                            defaultValue={DonationSource.BackStore}
                                            onChange={(e: any) => this.updateSource(e.target.value as DonationSource)}>
                                            <MenuItem value={DonationSource.FrontStore}>{i18next.t('LABEL_FRONT_STORE')}</MenuItem>
                                            <MenuItem value={DonationSource.BackStore}>{i18next.t('LABEL_BACK_STORE')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        required
                                        variant="outlined"
                                        error={errors.weight}
                                        type="number"
                                        label={i18next.t('LABEL_WEIGHT')}
                                        value={isNaN(this.state.donation.weight) ? "" : this.state.donation.weight}
                                        onChange={(e) => this.updateWeight(parseFloat(e.target.value))}
                                        inputProps={{ min: "0", step: "any" }}
                                        helperText={errors.weight ? i18next.t('VALIDATION_WEIGHT') : ""}
                                        fullWidth />
                                </Grid>
                                <Grid item>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel htmlFor="add-donation-weight-unit-label">{i18next.t('LABEL_UNIT')}</InputLabel>
                                        <Select
                                            labelId="add-donation-weight-unit-label"
                                            label={i18next.t('LABEL_UNIT')}
                                            defaultValue={WeightUnit.Pounds}
                                            onChange={(e: any) => this.updateWeightUnit(e.target.value as WeightUnit)}>
                                            <MenuItem value={WeightUnit.Pounds}>{i18next.t('LABEL_POUNDS')}  (lbs)</MenuItem>
                                            <MenuItem value={WeightUnit.Kilogram}>{i18next.t('LABEL_KILOGRAMS')}  (kg)</MenuItem>
                                            <MenuItem value={WeightUnit.BananaBox}>{i18next.t('LABEL_BANANA_BOX')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Paper>
                                        <Typography className="donation-categories-header" variant="subtitle1" color={errors.categorySum ? "error" : "inherit"}>
                                            {i18next.t('LABEL_CATEGORIES')}
                                        </Typography>
                                        <Typography className="donation-categories-desc">
                                            {i18next.t('LABEL_CATEGORIES_DESC')}
                                        </Typography>
                                        <div className="donation-categories">
                                            <CategoryList categories={this.state.donation.categories} updateCategory={(categoryIndex, fraction) => this.updateCategory(categoryIndex, fraction)} hasError={errors.categorySum} />
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                    }
                </Body>
                <Footer>
                    <Button variant="contained" disableElevation fullWidth color="primary" onClick={(e: any) => this.submit()} disabled={this.state.loading}>
                        {i18next.t('ADD_DONATION')}
                    </Button>
                    <Button variant="contained" disableElevation fullWidth color="secondary" onClick={(e: any) => this.goBack()}>
                        {i18next.t('BUTTON_CANCEL')}
                    </Button>
                </Footer>
            </Page>
        );
    }
}