import * as React from 'react';
import { Component } from 'react';
import i18next from 'i18next'; 
import { ListItem, ListItemText, Box, Tooltip, IconButton, Typography } from '@material-ui/core';
import { Check, Cancel, Edit, Delete } from '@material-ui/icons';
import { DateBox } from 'components/date-box/date-box';
import { DonationsListItemProps } from './donations-list-item-props';
import { DonationsListItemState } from './donations-list-item-state';

export class DonationsListItem extends Component<DonationsListItemProps, DonationsListItemState> {
    constructor(props: DonationsListItemProps) {
        super(props);
        this.state = new DonationsListItemState();
    }

    editDonation() {
        const pathname = this.props.route.location.pathname;
        this.props.route.history.replace(`/donations/edit/${this.props.campaignDonation.id}/${this.props.campaignDonation.campaignId}`, {
            from: pathname,
            year: this.props.campaignDonation.date.getFullYear(),
            month: this.props.campaignDonation.date.getMonth() + 1
        });
    }

    render() {
        let weight = `${(this.props.campaignDonation.weightInPounds).toLocaleString(navigator.language)} lbs.`;
        let categories: string[] = [];

        this.props.campaignDonation.categories.sort((a, b) => {
            if (a.category.displayOrder < b.category.displayOrder) {
                return -1;
            }
            else if (a.category.displayOrder > b.category.displayOrder) {
                return 1;
            }
            else {
                return ('' + a.category.name).localeCompare(b.category.name);
            }
        });

        

        for (const c of this.props.campaignDonation.categories) {
            if (c.fraction) {                
                categories.push(`${(c.fraction! * 100).toFixed(2)}% ${i18next.t("LABEL_" + (c.category?.name).split('&').join('').split(' ').join('_').toUpperCase())}`);   
            }
        }
        
        return (
            <ListItem key={this.props.campaignDonation.id} disabled={this.props.submitted}>
                <ListItemText>
                    <Box display="flex" flexDirection="row" flexWrap="nowrap">
                        <Box>
                            <DateBox month={this.props.campaignDonation.date.getMonth()} day={this.props.campaignDonation.date.getDate()} />
                        </Box>
                        <Box flex="1" paddingLeft={2} paddingRight={2}>
                            <Typography variant="subtitle1">
                                <b>{weight}</b>
                            </Typography>
                            {
                                categories.map((c, i) => <div key={i}>{c}</div>)
                            }
                            <div>{this.props.campaignDonation.store?.name}</div>
                            <div>{this.props.campaignDonation.source == 0 ? i18next.t('LABEL_BACK_STORE') : i18next.t('LABEL_FRONT_STORE')}</div>
                            <div>{this.props.campaignDonation.campaignName}</div>
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <Tooltip title={i18next.t('TOOLTIP_EDIT')}>
                                <IconButton size="small" edge="end" aria-label="edit" disabled={this.props.submitted} onClick={() => this.editDonation()}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            {
                                this.state.confirmDelete ?
                                    [
                                        <Tooltip title={i18next.t('TOOLTIP_CONFIRM')}>
                                            <IconButton size="small" edge="end" aria-label="check" onClick={() => this.props.onDelete()}>
                                                <Check />
                                            </IconButton>
                                        </Tooltip>,
                                        <Tooltip title={i18next.t('BUTTON_CANCEL')}>
                                            <IconButton size="small" edge="end" aria-label="cancel" onClick={() => this.setState({ confirmDelete: false })}>
                                                <Cancel />
                                            </IconButton>
                                        </Tooltip>
                                    ]
                                    :
                                    <Tooltip title={i18next.t('TOOLTIP_DELETE')}>
                                        <IconButton size="small" edge="end" aria-label="delete" disabled={this.props.submitted} onClick={() => this.setState({ confirmDelete: true })}>
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                            }
                        </Box>
                    </Box>
                </ListItemText>
            </ListItem>
        );
    }
}