import { API } from "models";

export class FeedbackService {
    public static async postFeedback(user: API.Responses.User, feedback: API.Requests.Feedback): Promise<void> {
        let options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({
                contactId: feedback.contactId,
                message: feedback.message,
                contactEmail: user.contactEmail
            })
        };

        let response = await fetch(`/api/feedback`, options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }
	}
}