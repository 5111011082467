import { API } from "models";

export class ConfigService {
    public static async getAuthConfig(): Promise<API.Responses.AuthConfig> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let response = await fetch(`/api/config/auth-config`, options);

        let json = await response.json();

        if (!response.ok) {
            if (json != null) {
                throw new Error(json.message);
            }
            else {
                throw new Error(response.status.toString());
            }
        }

        return new API.Responses.AuthConfig(json.aadClientId, json.aadTenantId, json.aadB2CClientId, json.aadB2CSignInName, json.aadB2CSignInAuth, json.aadB2CForgotPasswordName, json.aadB2CForgotPasswordAuth, json.aadB2CAuthDomain, json.showEmptyPageForFacebookUser, json.messageForFacebookUser, json.urlForFacebookUser);
    }
}