import { API } from 'models';

export class ContactService {
    public static async getContacts(token: string): Promise<API.Responses.Contact[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch('/api/contact', options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let contacts: API.Responses.Contact[] = [];

        for (let c of json) {
            contacts.push(new API.Responses.Contact(c.id, c.name, c.emailAddress));
        }

        return contacts;
    }
}