export class DonationCategory {
	categoryId: string;
	categoryName: string;
	fraction: number | null;

	constructor(categoryId: string, categoryName: string, fraction: number | null) {
		this.categoryId = categoryId;
		this.categoryName = categoryName;
		this.fraction = fraction;
	}
}