import * as React from 'react';
import { Component } from 'react';
import { Button, Divider, List, Box, Zoom, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core';
import i18next from 'i18next';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from "@date-io/moment";
import 'moment/locale/fr'; 
import { BranchService, DonationService, CampaignService } from 'services';
import { StatusMessage } from 'models';
import { Donation, CampaignDonation, CampaignDonationCategory } from 'models/api/responses';
import { RFP_Id, RFP_name } from 'models/api';
import { DonationsListItem } from './donations-list-item';
import Header from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Body } from 'components/body/body';
import { Page } from 'components/page/page';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { DonationsListProps } from './donations-list-props';
import { DonationsListState } from './donations-list-state';

export class DonationsList extends Component<DonationsListProps, DonationsListState> {
    constructor(props: DonationsListProps) {
        super(props);
        this.state = new DonationsListState(this.props.year, this.props.month);
    }

    componentDidMount() {
        const statusMessage: StatusMessage | undefined = this.props.route.location.state?.statusMessage;
        if (statusMessage) {
            this.showStatusMessage(statusMessage);
        }

        const year: number | undefined = this.props.route.location.state?.year;
        const month: number | undefined = this.props.route.location.state?.month;
        if (year && month) {
            this.setState({ year: year, month: month }, () => { this.fetchDonations() });
        }
        else {
            this.fetchDonations();
        }
    }

    componentDidUpdate(prevProps: DonationsListProps, prevState: DonationsListState) {
        if (prevProps.user.branchId !== this.props.user.branchId) {
            this.fetchDonations();
        }
    }

    async fetchDonations() {
        this.setState({ loading: true });
        try {
            const submitted = await BranchService.getIsMonthSubmitted(this.props.user.token, this.props.user.branchId, this.state.year, this.state.month);
            const comments = await BranchService.getSubmissionComments(this.props.user.token, this.props.user.branchId, this.state.year, this.state.month);
            const donations = await BranchService.getDonations(this.props.user.token, this.props.user.branchId, this.state.year, this.state.month);
            const campaignDonations = await CampaignService.getDonations(this.props.user.token, this.props.user.branchId, this.state.year, this.state.month);

            var x = donations.map(function (d) {
                //CampaignDonationCategory
                var cdc = d.categories.map(function (c) {
                    
                    var i = new CampaignDonationCategory(c.id, RFP_Id, RFP_Id, c.categoryId, c.category, c.fraction);
                    return i;
                })
                var item = new CampaignDonation(d.id, RFP_Id, RFP_name, d.branchId, d.date, d.storeId, d.store, d.source, d.weightInPounds, d.weight, d.weightUnit, cdc, d.submitted, d.submitDate);
                return item;
            })

            this.setState({ donations: donations, campaignDonations: [...campaignDonations, ...x], submitted: submitted, submissionComments: comments });
        } finally {
            this.setState({ loading: false });
        }
    }
    
    async deleteCampaignDonation(donation: CampaignDonation) {
        this.setState({ loading: true });
        try {
            if (donation.campaignId == RFP_Id)
            {
                await DonationService.deleteDonation(this.props.user.token, donation.id!);
            }
            else
            {
                await DonationService.deleteCampaignDonation(this.props.user.token, donation.id!);
            }
            
            await this.fetchDonations();
        } finally {
            this.setState({ loading: false });
        }
    }

    async submit() {
        this.setState({ loading: true });
        try {
            await DonationService.submitDonations(this.props.user.token, this.props.user.branchId, this.state.year, this.state.month, this.state.submissionComments, this.props.user.contactEmail);
            const submittedStatusMessage = new StatusMessage(`${i18next.t('NOTE_REPORT_SUBMISSION_STATUS')} ${('0' + this.state.month).slice(-2)}/${this.state.year}.`, 'success');
            this.props.route.history.replace('/', {
                statusMessage: submittedStatusMessage,
                year: this.state.year,
                month: this.state.month
            });
        }
        catch (e) {
            console.log(e.message);
        }
        finally {
            this.setState({ loading: false });
        }
    }

    updateMonth(d: any | null) {
        var date = d.toDate();
        if (date !== null && !isNaN(date.getDate())) {
            this.setState({ month: date!.getMonth() + 1 }, () => this.fetchDonations());
        }
    }

    onBranchSelected(branchId) {
        this.props.onBranchSelected(branchId);
        this.hideStatusMessage();
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private addDonation() {
        this.props.route.history.replace('/donations/add', { from: this.props.route.location.pathname, year: this.state.year, month: this.state.month });
    }

    private handleCancel() {
        this.setState({ showSubmitConfirmation: false });
    }

    private handleSubmit() {
        this.setState({ showSubmitConfirmation: false });
        this.submit();
    }

    private showSubmitDialog() {
        this.setState({ showSubmitConfirmation: true });
    }

    private updateSubmissionComments(comments: string) {
        this.setState({ submissionComments: comments });
    }

    render() {
        moment.locale(this.props.user && this.props.user.locale ? this.props.user.locale : 'en'); // it is required to select default locale manually
        const date = moment(new Date(this.state.year, this.state.month - 1));

        return (
            <Page>
                <Header
                    route={this.props.route}
                    title={i18next.t('MONTHLY_DONATIONS')}
                    branches={this.props.user.branches}
                    branchId={this.props.user.branchId}
                    onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                    user={this.props.user}
                    onLogout={(user) => this.props.onLogout(user)}
                    siteLocale={this.props.siteLocale}
                    handleLangChange={(selectedLocale) => this.props.handleLangChange(selectedLocale)}
                >
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={(this.props.user && this.props.user.locale ? this.props.user.locale : 'en')}>
                        <KeyboardDatePicker
                            autoOk={true}
                            disableToolbar={true}
                            className="date-picker"
                            format="MMM yyyy"
                            views={["month"]}
                            value={date} 
                            onChange={(e) => this.updateMonth(e)}
                            variant="inline"
                        />
                    </MuiPickersUtilsProvider>
                </Header>
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body>
                    {
                        this.state.loading ?
                            <LoadingBar/>
                            :
                            <div>
                                <List disablePadding>
                                    {
                                        
                                        this.state.campaignDonations.map((campaignDonation, i) => {
                                            return (
                                                <Zoom key={campaignDonation.id!} in={true} timeout={500} style={{ transitionDelay: `${i * 250}ms` }}>
                                                    <div>
                                                        <DonationsListItem
                                                            route={this.props.route}
                                                            campaignDonation={campaignDonation}
                                                            onDelete={() => this.deleteCampaignDonation(campaignDonation)}
                                                            submitted={this.state.submitted} />
                                                        <Divider component="li" />
                                                    </div>
                                                </Zoom>
                                            );
                                        })                                       
                                    }
                                </List>
                                <Dialog
                                    open={this.state.showSubmitConfirmation}
                                    onClose={() => this.handleCancel()}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description">
                                    <DialogTitle id="alert-dialog-title">{i18next.t('NOTE_SUBMIT_REPORT_QUESTION')}</DialogTitle>
                                    <DialogContent>
                                        <div id="submission-comments">
                                            <TextField
                                                placeholder={i18next.t('LABEL_COMMENTS')}
                                                value={this.state.submissionComments}
                                                onChange={(e) => this.updateSubmissionComments(e.target.value)}
                                                variant="outlined"
                                                rows={5}
                                                multiline
                                                fullWidth
                                            />
                                        </div>
                                        <DialogContentText id="alert-dialog-description" variant="body2">
                                            {i18next.t('NOTE_REPORT_SUBMISSION_CONFIMATION')} <a target="_top" href="mailto:retailprogram@foodbankscanada.ca">retailprogram@foodbankscanada.ca</a>.
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.handleCancel()} color="primary">
                                            {i18next.t('BUTTON_CANCEL')}
                                        </Button>
                                        <Button onClick={() => this.handleSubmit()} color="primary" autoFocus>
                                            {i18next.t('BUTTON_SUBMIT')}
                                         </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                    }
                </Body>
                <Footer>
                    {
                        this.state.submitted ?
                            <Box textAlign="center">
                                <Typography variant="body1" gutterBottom>{i18next.t('NOTE_REPORT_SUBMITTED')}</Typography>
                                <Typography variant="caption">{i18next.t('NOTE_QUESTIONS')} Email <a target="_top" href="mailto:retailprogram@foodbankscanada.ca">retailprogram@foodbankscanada.ca</a>.</Typography>
                            </Box>
                            :
                            [
                                <Button key={0} variant="contained" disableElevation fullWidth color="primary" onClick={() => this.addDonation()}>
                                    {i18next.t('BUTTON_ADD_DONATION')}
                                </Button>,
                                <Button key={1} variant="contained" disableElevation fullWidth color="primary" onClick={() => this.showSubmitDialog()}>
                                    {i18next.t('BUTTON_SUBMIT_REPORT')}
                                </Button>
                            ]
                    }
                </Footer>
            </Page>
        );
    }
}