import * as React from 'react';
import { Component } from 'react';
import { Grid, TextField, FormControl, Select, MenuItem, Button, Typography, InputLabel } from '@material-ui/core';
import i18next from 'i18next';
import { StatusMessage } from 'models';
import { BranchService, UserService } from 'services';
import { Body } from 'components/body/body';
import { Footer } from 'components/footer/footer';
import Header from 'components/header/header';
import { Page } from 'components/page/page';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { UpdateBranchDetailsProps } from './update-branch-details-props';
import { UpdateBranchDetailsState } from './update-branch-details-state';

export class UpdateBranchDetails extends Component<UpdateBranchDetailsProps, UpdateBranchDetailsState> {
    constructor(props: UpdateBranchDetailsProps) {
        super(props);
        this.state = new UpdateBranchDetailsState();
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            await UserService.loadMessengerExtensionsSDK();
            const branch = await BranchService.getBranch(this.props.user.token, this.props.user.branchId);
            this.setState({ branch: branch });
        } finally {
            this.setState({ loading: false });
        }
    }

    async save(): Promise<void> {
        if (this.hasErrors()) {
            return;
        }
        this.setState({ loading: true });
        try {
            await BranchService.updateBranch(this.props.user.token, this.props.user.branchId, this.state.branch);
            this.showStatusMessage(new StatusMessage(i18next.t('NOTE_ORG_UPDATE_SUCCESS'), 'success'));
        } catch (e) {
            if (e.message.includes('400')) {
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_ORG_UPDATE_ADDRESS_FAILED'), 'error'));
            }
            else {
                this.showStatusMessage(new StatusMessage(i18next.t('NOTE_ORG_UPDATE_FAILED'), 'error'));
            }
        } finally {
            this.setState({ loading: false });
        }
    }

    showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    cancel() {
        window.MessengerExtensions.requestCloseBrowser(
            function () {},
            function (error) {}
        );
    }

    updateName(name: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                name: name
            },
            errors: {
                ...this.state.errors,
                name: this.isFieldEmpty(name)
            }
        });
    }

    updatePhoneNumber(phoneNumber: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                phoneNumber: phoneNumber
            },
            errors: {
                ...this.state.errors,
                phoneNumber: this.isFieldEmpty(phoneNumber)
            }
        });
    }

    updateEmail(email: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                email: email
            },
            errors: {
                ...this.state.errors,
                email: this.isFieldEmpty(email) || !this.isValidEmail(email)
            }
        });
    }

    updateAddress1(address1: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                address1: address1
            },
            errors: {
                ...this.state.errors,
                address1: this.isFieldEmpty(address1)
            }
        });
    }

    updateAddress2(address2: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                address2: address2
            }
        });
    }

    updateProvince(province: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                province: province
            }
        });
    }

    updateCity(city: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                city: city
            },
            errors: {
                ...this.state.errors,
                city: this.isFieldEmpty(city)
            }
        });
    }

    updatePostalCode(postalCode: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                postalCode: postalCode
            },
            errors: {
                ...this.state.errors,
                postalCode: this.isFieldEmpty(postalCode)
            }
        });
    }

    updateWebsite(website: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                website: website
            }
        })
    }

    updateOperatingHours(operatingHours: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                operatingHours: operatingHours
            }
        });
    }

    updateContactName(contactName: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                contactName: contactName
            }
        })
    }

    updateContactTitle(contactTitle: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                contactTitle: contactTitle
            }
        });
    }

    updateContactEmail(contactEmail: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                contactEmail: contactEmail
            }
        });
    }

    updateContactPhoneNumber(contactPhoneNumber: string) {
        this.setState({
            branch: {
                ...this.state.branch,
                contactPhoneNumber: contactPhoneNumber
            }
        });
    }

    updateNumStaff(numStaff: number) {
        this.setState({
            branch: {
                ...this.state.branch,
                numStaff: isNaN(numStaff) ? null : numStaff
            }
        });
    }

    updateNumVolunteers(numVolunteers: number) {
        this.setState({
            branch: {
                ...this.state.branch,
                numVolunteers: isNaN(numVolunteers) ? null : numVolunteers
            }
        });
    }

    updateNumServedInPreviousYear(numServedInPreviousYear: number) {
        this.setState({
            branch: {
                ...this.state.branch,
                numServedInPreviousYear: isNaN(numServedInPreviousYear) ? null : numServedInPreviousYear
            }
        });
    }

    updateTotalPoundsDistributedAnnually(totalPoundsDistributedAnnually: number) {
        this.setState({
            branch: {
                ...this.state.branch,
                totalPoundsDistributedAnnually: isNaN(totalPoundsDistributedAnnually) ? null : totalPoundsDistributedAnnually
            }
        });
    }

    isFieldEmpty(value: string) {
        if (value === '') {
            return true;
        }
        return false;
    }

    isValidEmail(email: string) {
        var re = /[^@]+@[^.]+\..+/;
        return re.test(email);
    }

    hasErrors() {
        return (this.state.errors.name
            || this.state.errors.phoneNumber
            || this.state.errors.email
            || this.state.errors.address1
            || this.state.errors.city
            || this.state.errors.postalCode);
    }

    onBranchSelected(branchId) {
        this.props.onBranchSelected(branchId);
        this.hideStatusMessage();
    }

    async componentDidUpdate(prevProps: UpdateBranchDetailsProps, prevState: UpdateBranchDetailsState) {
        if (prevProps.user.branchId !== this.props.user.branchId) {
            try {
                this.setState({ loading: true });
                const branch = await BranchService.getBranch(this.props.user.token, this.props.user.branchId);
                this.setState({ branch: branch });
            } finally {
                this.setState({ loading: false });
            }
        }
    }

    render() {
        const { errors } = this.state;
        return (
            <Page>
                <Header
                    route={this.props.route}
                    title={i18next.t('ORG_UPDATE_DETAILS')}
                    branches={this.props.user.branches}
                    branchId={this.props.user.branchId}
                    onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                    user={this.props.user}
                    onLogout={(user) => this.props.onLogout(user)}
                    siteLocale={this.props.siteLocale}
                    handleLangChange={(selectedLocale) => this.props.handleLangChange(selectedLocale)} />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar/>
                            :
                            <Grid container direction="column" wrap="nowrap" spacing={3}>
                                <Grid item>
                                    <Grid container direction="column" wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {i18next.t('ORG_FOOD_BANK_CONTACT_INFO')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.name}
                                                label={i18next.t('ORG_NAME')}
                                                value={this.state.branch.name}
                                                onChange={(e) => this.updateName(e.target.value)}
                                                helperText={errors.name ? i18next.t('ORG_NAME_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.phoneNumber}
                                                label={i18next.t('ORG_PHONE')}
                                                value={this.state.branch.phoneNumber}
                                                onChange={(e) => this.updatePhoneNumber(e.target.value)}
                                                helperText={errors.phoneNumber ? i18next.t('ORG_PHONE_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.email}
                                                label={i18next.t('ORG_EMAIL')}
                                                value={this.state.branch.email}
                                                onChange={(e) => this.updateEmail(e.target.value)}
                                                helperText={errors.email ? i18next.t('ORG_EMAIL_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.address1}
                                                label={i18next.t('ORG_ADDRESS_1')}
                                                value={this.state.branch.address1}
                                                onChange={(e) => this.updateAddress1(e.target.value)}
                                                helperText={errors.address1 ? i18next.t('ORG_ADDRESS_1_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_ADDRESS_2')}
                                                value={this.state.branch.address2 ?? ''}
                                                onChange={(e) => this.updateAddress2(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.city}
                                                label={i18next.t('ORG_CITY')}
                                                value={this.state.branch.city}
                                                onChange={(e) => this.updateCity(e.target.value)}
                                                helperText={errors.city ? i18next.t('ORG_CITY_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel htmlFor="update-branch-label">Province</InputLabel>
                                                <Select
                                                    labelId="update-branch-label"
                                                    label={i18next.t('ORG_PROVINCE')}
                                                    value={this.state.branch.province}
                                                    onChange={(e: any) => this.updateProvince(e.target.value)}>
                                                    {
                                                        this.state.provinces.map(p => <MenuItem key={p.code} value={p.code}>{i18next.t(p.name.split(" ").join("_").toUpperCase())}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                required
                                                variant="outlined"
                                                error={errors.postalCode}
                                                label={i18next.t('ORG_POSTAL_CODE')}
                                                value={this.state.branch.postalCode}
                                                onChange={(e) => this.updatePostalCode(e.target.value)}
                                                helperText={errors.postalCode ? i18next.t('ORG_POSTAL_CODE_VALIDATE') : ""}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_WEBSITE')}
                                                value={this.state.branch.website ?? ''}
                                                onChange={(e) => this.updateWebsite(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_OPERATING_HOURS')}
                                                value={this.state.branch.operatingHours ?? ''}
                                                onChange={(e) => this.updateOperatingHours(e.target.value)}
                                                multiline
                                                rows={3}
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column" wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {i18next.t('ORG_MAIN_CONTACT')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_FULL_NAME')}
                                                value={this.state.branch.contactName ?? ''}
                                                onChange={(e) => this.updateContactName(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_JOB_TITLE')}
                                                value={this.state.branch.contactTitle ?? ''}
                                                onChange={(e) => this.updateContactTitle(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_EMAIL')}
                                                value={this.state.branch.contactEmail ?? ''}
                                                onChange={(e) => this.updateContactEmail(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_PHONE')}
                                                value={this.state.branch.contactPhoneNumber ?? ''}
                                                onChange={(e) => this.updateContactPhoneNumber(e.target.value)}
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Grid container direction="column" wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                {i18next.t('ORG_STATISTICS')}
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_NUM_STAFF')}
                                                type="number"
                                                value={this.state.branch.numStaff ?? ''}
                                                onChange={(e) => this.updateNumStaff(parseInt(e.target.value))}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_NUM_VOLUNTEERS')}
                                                type="number"
                                                value={this.state.branch.numVolunteers ?? ''}
                                                onChange={(e) => this.updateNumVolunteers(parseInt(e.target.value))}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_NUM_PEOPLE_SERVED')}
                                                type="number"
                                                value={this.state.branch.numServedInPreviousYear ?? ''}
                                                onChange={(e) => this.updateNumServedInPreviousYear(parseInt(e.target.value))}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                variant="outlined"
                                                label={i18next.t('ORG_NUM_POUNDS_FOOD')}
                                                type="number"
                                                value={this.state.branch.totalPoundsDistributedAnnually ?? ''}
                                                onChange={(e) => this.updateTotalPoundsDistributedAnnually(parseFloat(e.target.value))}
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                    }
                </Body>
                <Footer>
                    <Button onClick={() => this.save()} variant="contained" color="primary" disableElevation fullWidth>
                        {i18next.t('BUTTON_SAVE')}
                    </Button>
                    <Button onClick={() => this.cancel()} variant="contained" color="secondary" disableElevation fullWidth>
                        {i18next.t('BUTTON_CANCEL')}
                    </Button>
                </Footer>
            </Page>
        );
    }
}
