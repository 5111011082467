export class BgData {
    logo: string;
    blackLetterLogo: string;
    title: string;
    content: string;
    bgImage: string;
    contentWidth: number;
    frBlackLetterLogo: string;
    frLogo: string;
    frTitle: string;
    frContent: string;
    frBgImage: string;
    frContentWidth: number;

    constructor(logo: string, blackLetterLogo: string, title: string, content: string, bgImage: string, contentWidth: number, frLogo: string, frBlackLetterLogo: string, frTitle: string, frContent: string, frBgImage: string, frContentWidth: number) {
        this.logo = logo;
        this.blackLetterLogo = blackLetterLogo;
        this.title = title;
        this.content = content;
        this.bgImage = bgImage;
        this.contentWidth = contentWidth;
        this.frLogo = frLogo;
        this.frBlackLetterLogo = frBlackLetterLogo;
        this.frTitle = frTitle;
        this.frContent = frContent;
        this.frBgImage = frBgImage;
        this.frContentWidth = frContentWidth;
    }
}