import { Province } from 'models';
import { Branch } from 'models/api/requests';
import { StatusMessage } from 'models';

export class UpdateBranchDetailsState {
	branch: Branch;
	provinces: Province[];
    loading: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    errors: ValidationErrors;

	constructor() {
		this.branch = new Branch();
        this.provinces = [
            new Province('ON', 'Ontario'),
            new Province('QC', 'Quebec'),
            new Province('NS', 'Nova Scotia'),
            new Province('NB', 'New Brunswick'),
            new Province('MB', 'Manitoba'),
            new Province('BC', 'British Columbia'),
            new Province('PE', 'Prince Edward Island'),
            new Province('SK', 'Saskatchewan'),
            new Province('AB', 'Alberta'),
            new Province('NL', 'Newfoundland and Labrador'),
            new Province('NT', 'Northwest Territories'),
            new Province('YT', 'Yukon'),
            new Province('NU', 'Nunavut')
        ];
        this.loading = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.errors = new ValidationErrors();
	}
}

class ValidationErrors {
    name: boolean;
    phoneNumber: boolean;
    email: boolean;
    address1: boolean;
    city: boolean;
    postalCode: boolean;

    constructor() {
        this.name = false;
        this.phoneNumber = false;
        this.email = false;
        this.address1 = false;
        this.city = false;
        this.postalCode = false;
    }
}