import { Color } from "@material-ui/lab/Alert";

export class StatusMessage {
	text: string;
	severity: Color;

	constructor(text: string = '', severity: Color = "info") {
		this.text = text;
		this.severity = severity;
	}
}