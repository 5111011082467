import { User } from "models/api/responses";

export class AppState {
    user: User | null;
    loading: boolean = false;
    siteLocale: string;

    constructor(user: User | null) {
        this.user = user;
        this.loading = false;
        this.siteLocale = 'en';
    }
}