export class AuthConfig {
    aadClientId: string;
    aadTenantId: string;
    aadB2CClientId: string;
    aadB2CSignInName: string;
    aadB2CSignInAuth: string;
    aadB2CForgotPasswordName: string;
    aadB2CForgotPasswordAuth: string;
    aadB2CAuthDomain: string;
    showEmptyPageForFacebookUser: boolean;
    messageForFacebookUser: string;
    urlForFacebookUser: string;

    constructor(aadClientId: string, aadTenantId: string, aadB2CClientId: string, aadB2CSignInName: string, aadB2CSignInAuth: string, aadB2CForgotPasswordName: string, aadB2CForgotPasswordAuth: string, aadB2CAuthDomain: string, showEmptyPageForFacebookUser: boolean, messageForFacebookUser: string, urlForFacebookUser: string) {
        this.aadClientId = aadClientId;
        this.aadTenantId = aadTenantId;
        this.aadB2CClientId = aadB2CClientId;
        this.aadB2CSignInName = aadB2CSignInName;
        this.aadB2CSignInAuth = aadB2CSignInAuth;
        this.aadB2CForgotPasswordName = aadB2CForgotPasswordName;
        this.aadB2CForgotPasswordAuth = aadB2CForgotPasswordAuth;
        this.aadB2CAuthDomain = aadB2CAuthDomain;
        this.showEmptyPageForFacebookUser = showEmptyPageForFacebookUser;
        this.messageForFacebookUser = messageForFacebookUser;
        this.urlForFacebookUser = urlForFacebookUser;
    }
}