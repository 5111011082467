import * as React from 'react';
import { Component } from 'react';
import i18next from 'i18next';
import { Button, FormControl, Select, MenuItem, OutlinedInput, Box, Dialog, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { StatusMessage } from 'models';
import { FeedbackService, UserService, ContactService } from 'services';
import { Page } from 'components/page/page';
import { Body } from 'components/body/body';
import Header from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { FeedbackState } from './feedback-state';
import { FeedbackProps } from './feedback-props';

import './feedback.css';

export class Feedback extends Component<FeedbackProps, FeedbackState> {
    constructor(props) {
        super(props);
        this.state = new FeedbackState();
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true });
            const contacts = await ContactService.getContacts(this.props.user.token);
            if (contacts.length > 0) {
                this.setState({ 
                    contacts: contacts, 
                    feedback: { 
                        ...this.state.feedback, 
                        contactId: contacts[0].id 
                    } 
                });
            }
            await UserService.loadMessengerExtensionsSDK();
        } finally {
            this.setState({ loading: false });
        }
    }

    setContactId(department: string) {
        this.setState({ feedback: { ...this.state.feedback, contactId: department } });
    }

    setMessage(message: string) {
        this.setState({ feedback: { ...this.state.feedback, message: message } });
    }

    async submit() {
        try {
            this.setState({ loading: true });
            await FeedbackService.postFeedback(this.props.user, this.state.feedback);
            this.handlePopupOpen();
            this.hideStatusMessage();
            this.setMessage('');
        } catch (e) {
            this.showStatusMessage(new StatusMessage(i18next.t('NOTE_FEEDBACK_ERROR'), 'error'));
        } finally {
            this.setState({ loading: false });
        }
    }

    showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    cancel() {
        window.MessengerExtensions.requestCloseBrowser(
            function () { },
            function (error) { }
        );
    }

    private handlePopupOpen() {
        this.setState({ showFeedbackSentPopup: true });
    }

    private handlePopupClose() {
        this.setState({ showFeedbackSentPopup: false });
    }

    render() {
        return (
            <Page>
                <Header 
                    route={this.props.route} 
                    title={i18next.t('HELP_FEEDBACK')} 
                    showHamburgerButton={false}
                    />
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar />
                            :
                            <>
                                <Box display="flex" flexDirection="column" height="100%">
                                    <Box marginBottom={1}>
                                        <FormControl variant="outlined" fullWidth>
                                            <Select
                                                value={this.state.feedback.contactId}
                                                onChange={(e: any) => this.setContactId(e.target.value)}>
                                                {
                                                    this.state.contacts.map(c => <MenuItem key={c.id} value={c.id}>{i18next.t("FEEDBACK_" + c.name.split(' ').join('_').split('/').join('_').toUpperCase())}</MenuItem>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <OutlinedInput
                                        placeholder={i18next.t('TYPE_YOUR_RESPONSE')}
                                        value={this.state.feedback.message}
                                        onChange={(e: any) => this.setMessage(e.target.value)}
                                        rows={15}
                                        multiline
                                        fullWidth />
                                </Box>

                                <Dialog
                                    open={this.state.showFeedbackSentPopup}
                                    onClose={() => this.handlePopupClose()}>
                                    <DialogContent>
                                        <div className="feedback-received-english">
                                            <Typography variant="subtitle1" gutterBottom><Box fontWeight="fontWeightMedium">*{i18next.t('AUTOMATED_MESSAGE')}</Box></Typography>
                                            <Typography gutterBottom>{i18next.t('THANK_YOU_FEEDBACK')}</Typography>
                                            <Typography gutterBottom>{i18next.t('WILL_RESPOND')}</Typography>
                                            <div className="feedback-regards">
                                                <Typography gutterBottom>{i18next.t('KIND_REGARDS')}</Typography>
                                                <Typography gutterBottom>{i18next.t('SUPPORT_TEAM')}</Typography>
                                            </div>
                                        </div>
                                        <hr />                                        
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.handlePopupClose()} color="primary">
                                            {i18next.t('BUTTON_CLOSE')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                    }
                </Body>
                <Footer>
                    <Button variant="contained" disableElevation fullWidth color="primary" onClick={(e:any) => this.submit()}>
                        {i18next.t('BUTTON_SUBMIT')}
                    </Button>
                    <Button variant="contained" disableElevation fullWidth color="secondary" onClick={(e: any) => this.cancel()}>
                        {i18next.t('BUTTON_CANCEL')}
                    </Button>
                </Footer>
            </Page>
        );
    }
}