import { API } from "models";

export class CampaignService {
    public static async getCampaigns(token: string, branchId: string): Promise<API.Responses.Campaign[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/campaigns`, options);
        
        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let campaigns: API.Responses.Campaign[] = [];

        for (let s of json) {
            campaigns.push(new API.Responses.Campaign(s.id, s.name));
        }

        return campaigns;
    }

    public static async getDonations(token: string, branchId: string, year: number, month: number): Promise<API.Responses.CampaignDonation[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let response = await fetch(`/api/branch/${branchId}/campaigndonations/${year}/${month}`, options);
        
        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let campaignDonations: API.Responses.CampaignDonation[] = [];
        
        for (let d of json) {
            campaignDonations.push(new API.Responses.CampaignDonation(d.id, d.campaignId, d.campaign.name, d.branchId, new Date(d.date + 'Z'), d.storeId, d.store, d.source, d.weightInPounds, d.weight, d.weightUnit, d.campaignCategories, d.submitted, d.submitDate));
        }
        
        return campaignDonations;
    }
}
