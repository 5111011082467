import * as React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router';
import { PrivateRouteProps } from './private-route-props';

export class PrivateRoute extends Component<PrivateRouteProps, {}> {
	render() {
		const { authenticated, ...rest } = this.props;

		return (
			authenticated ?
				<Route {...rest} />
				:
				<Redirect to={{
					pathname: '/login',
					search: this.props.location?.search,
					state: { from: this.props.location?.pathname, depth: 0 }
				}}/>
		);
	}
}