import { StatusMessage } from 'models';
import { Donation, CampaignDonation } from 'models/api/responses';

export class DonationsListState {
    year: number;
    month: number;
    campaignDonations: CampaignDonation[];
    donations: Donation[];
    loading: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    submitted: boolean;
    showSubmitConfirmation: boolean;
    submissionComments: string;

    constructor(year: number, month: number) {
        this.year = year;
        this.month = month;
        this.donations = [];
        this.campaignDonations = [];
        this.loading = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.submitted = false;
        this.showSubmitConfirmation = false;
        this.submissionComments = '';        
    }
}