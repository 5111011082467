import { StatusMessage } from 'models';
import { MonthlyReport } from 'models/api/responses';

export class MonthlyReportsState {
    year: number;
    reports: MonthlyReport[];
    loading: boolean;
    showStatusMessage: boolean;
    statusMessage: StatusMessage;
    minDonationYear: number;
    maxDonationYear: number;

    constructor() {
        this.year = new Date().getFullYear();
        this.reports = [];
        this.loading = false;
        this.showStatusMessage = false;
        this.statusMessage = new StatusMessage();
        this.minDonationYear = this.year;
        this.maxDonationYear = this.year;
    }
}