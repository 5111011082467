import * as React from 'react';
import { Component } from 'react';
import { DonationCategory } from 'models/api/requests';
import i18next from 'i18next';
import { Slider, Typography, Box, TextField } from '@material-ui/core';
import { CategoryListProps } from './category-list-props';
import { CategoryListState } from './category-list-state';

function getCategorySliderLabel(value: number) {
    if (isNaN(value)) {
        return `0%`;
    } else {
        return `${Math.round(value * 100)}%`
    }
}

export class CategoryList extends Component<CategoryListProps, CategoryListState> {
    constructor(props: CategoryListProps) {
        super(props);
        this.state = new CategoryListState();
    }

    onUpdateCategory(category: DonationCategory, fractionString: string, event) {
        const fraction = parseFloat(fractionString);
        if (isNaN(fraction)) {
            this.props.updateCategory(category, null);
        } else {
            this.props.updateCategory(category, fraction);
        }
    }

    onUpdateCategoryText(category: DonationCategory, value: string) {
        const fraction = parseFloat(value);
        if (isNaN(fraction)) {
            this.props.updateCategory(category, null);
        } else {
            this.props.updateCategory(category, Math.round(fraction)/100);
        }
    }

    onCommitUpdateCategory(category: DonationCategory, fractionString: string, event) {
        const fraction = parseFloat(fractionString);
        if (isNaN(fraction)) {
            this.props.updateCategory(category, null);
        } else {
            let categorySum = 0;
            for (let c of this.props.categories) {
                if (c.fraction !== null) {
                    categorySum += c.fraction * 100;
                }
            }

            if (categorySum >= 100) {
                this.props.updateCategory(category, ((100 - (categorySum - fraction*100))/100));
            }
            else {
                this.props.updateCategory(category, fraction);
            }
        }
    }

    render() {
        const { hasError } = this.props;
        let categorySum = 0.0;
        for (let c of this.props.categories) {
            if (c.fraction !== null) {
                categorySum += c.fraction;
            }
        }
        return (
            <Box width="100%">
                {
                    this.props.categories.map((c, categoryIndex) => (
                        <Box>
                            <Box display="flex" padding={1} alignItems="center" key={categoryIndex}>
                                <Typography variant="body1" style={{ width: 125, marginRight: 10 }}>{i18next.t("LABEL_" + c.categoryName.split('&').join('').split(' ').join('_').toUpperCase())}:</Typography>
                                <Box flex="1" padding={1}>
                                    <Slider
                                        step={0.01}
                                        min={0}
                                        max={1}
                                        valueLabelFormat={getCategorySliderLabel}
                                        valueLabelDisplay="on"
                                        value={c.fraction ?? 0}
                                        onChange={(event: any, fraction: any) => this.onUpdateCategory(c, fraction, event)}
                                        onChangeCommitted={(event: any, fraction: any) => this.onCommitUpdateCategory(c, fraction, event)}
                                    />
                                </Box>
                            </Box>

                            <Box >
                                <TextField
                                    required
                                    variant="outlined"
                                    type="number"
                                    label={i18next.t("LABEL_" + c.categoryName.split('&').join('').split(' ').join('_').toUpperCase())}
                                    value={c.fraction ? Math.round(c.fraction*100) : 0}
                                    inputProps={{ min: "0", step: "any" }}
                                    onChange={(e) => this.onUpdateCategoryText(c, e.target.value)}
                                    style={{ width: 125 }}
                                     />
                            </Box>
                        </Box>
                    ))
                }
                {
                    hasError ? <Typography color="error" variant="body1" align="right">{i18next.t('NOTE_CATEGORIES_VALIDATION')}</Typography> : null
                }
                <Typography color={ hasError ? "error" : "initial" } variant="body1" align="right">Total: {(categorySum * 100).toFixed(2)}%</Typography>
            </Box>
        );
    }
}