import { UserBranch } from "./user-branch";

export enum UserLoginType {
	None = 0,
	Admin = 1,
	AAD = 2,
	AADB2C = 3
}

export class User {
	id: string;
	token: string;
	branches: UserBranch[];
	branchId: string;
	contactEmail: string;
	locale: string; 
	workplace: boolean;
	loginType: UserLoginType;

	constructor(id: string, token: string, branches: UserBranch[], contactEmail: string, locale: string, workplace: boolean, loginType: UserLoginType = UserLoginType.None ) {
		this.id = id;
		this.token = token;
		this.branches = branches;
		if (this.branches.length > 0) {
			this.branchId = branches[0].branchId;
		} else {
			this.branchId = '';
		}
		this.contactEmail = contactEmail;
		this.locale = locale;
		this.workplace = workplace;
		this.loginType = loginType;
	}
}