import { CampaignDonationCategory } from "./campaign-donation-category";
import { Store } from "./store";
import { WeightUnit } from '..';
import { DonationSource } from "../donation-source";

export class CampaignDonation {
	id: string;
	campaignId: string;
	campaignName: string;
	branchId: string;
	date: Date;
	storeId: string;
	store: Store | null;
	source: DonationSource;
	weightInPounds: number;
	weight: number;
	weightUnit: WeightUnit;
	categories: CampaignDonationCategory[];
	submitted: boolean;
	submitDate: Date | null;

	constructor(id: string, campaignId:string, campaignName:string, branchId: string, date: Date, storeId: string, store: Store | null, source: DonationSource, weightInPounds: number, weight: number, weightUnit: WeightUnit, categories: CampaignDonationCategory[], submitted: boolean = false, submitDate: Date | null = null) {
		this.id = id;
		this.campaignId = campaignId;
		this.branchId = branchId;
		this.date = date;
		this.storeId = storeId;
		this.store = store;
		this.source = source;
		this.weightInPounds = weightInPounds;
		this.weight = weight;
		this.weightUnit = weightUnit;
		this.categories = categories;
		this.submitted = submitted;
		this.submitDate = submitDate;
		this.campaignName = campaignName;
	}
}