import { DonationCategory } from "./donation-category";
import { WeightUnit } from "models/api/weight-unit";
import { DonationSource } from "../donation-source";

export class Donation {
	branchId: string;
	date: Date;
	storeId: string;
	source: DonationSource
	weight: number;
	weightUnit: WeightUnit
	categories: DonationCategory[];
	createdBy: string;
	
	constructor(branchId: string = '', date: Date = new Date(), storeId: string = '', source: DonationSource = DonationSource.BackStore, weight: number = 0, weightUnit: WeightUnit = WeightUnit.Pounds, categories: DonationCategory[] = [], createdBy: string = '') {
		this.branchId = branchId;
		this.date = date;
		this.storeId = storeId;
		this.source = source;
		this.weight = weight;
		this.weightUnit = weightUnit;
		this.categories = categories;
		this.createdBy = createdBy;
	}
}