import { API } from 'models';

export class CategoryService {
    public static async getCategories(token: string): Promise<API.Responses.Category[]> {
        let options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        };
        let response = await fetch('/api/category', options);

        if (!response.ok) {
            throw new Error(response.status.toString());
        }

        let json = await response.json();

        let categories: API.Responses.Category[] = [];

        for (let c of json) {
            categories.push(new API.Responses.Category(c.id, c.name, c.displayOrder));
        }

        return categories;
	}
}