export class Category {
	id: string;
	name: string;
	displayOrder: number;

	constructor(id: string, name: string, displayOrder: number) {
		this.id = id;
		this.name = name;
		this.displayOrder = displayOrder;
	}
}