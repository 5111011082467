export class Branch {
	id: string;
	name: string;
	email: string;
	phoneNumber: string;
	province: string;
	city: string;
	address1: string;
	address2: string | null;
	postalCode: string;
	lat: number | null;
	lng: number | null;
	website: string | null;
	operatingHours: string | null;
	contactName: string | null;
	contactTitle: string | null;
	contactEmail: string | null;
	contactPhoneNumber: string | null;
	numStaff: number | null;
	numVolunteers: number | null;
	numServedInPreviousYear: number | null;
	totalPoundsDistributedAnnually: number | null;

	constructor(
		id: string,
		name: string,
		email: string,
		phoneNumber: string,
		province: string,
		city: string,
		address1: string,
		address2: string | null,
		postalCode: string,
		lat: number | null,
		lng: number | null,
		website: string | null,
		operatingHours: string | null,
		contactName: string | null,
		contactTitle: string | null,
		contactEmail: string | null,
		contactPhoneNumber: string | null,
		numStaff: number | null,
		numVolunteers: number | null,
		numServedInPreviousYear: number | null,
		totalPoundsDistributedAnnually: number | null
	) {
		this.id = id;
		this.name = name;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.province = province;
		this.city = city;
		this.address1 = address1;
		this.address2 = address2;
		this.postalCode = postalCode;
		this.lat = lat;
		this.lng = lng;

		this.website = website;
		this.operatingHours = operatingHours;
		this.contactName = contactName;
		this.contactTitle = contactTitle;
		this.contactEmail = contactEmail;
		this.contactPhoneNumber = contactPhoneNumber;
		this.numStaff = numStaff;
		this.numVolunteers = numVolunteers;
		this.numServedInPreviousYear = numServedInPreviousYear;
		this.totalPoundsDistributedAnnually = totalPoundsDistributedAnnually;
	}
}