import * as React from 'react';
import { Component } from 'react';
import { AppState } from 'AppState';
import { UserService } from '../../services';

import './page.css'

export class Page extends Component<any, AppState> {
    constructor(props: any) {
        super(props);
        this.state = new AppState(UserService.loadUser());
    }

    render() {
        const queryParams = new URLSearchParams(window.location.search);
        const ssoLogin = queryParams.get('sso');
        const fromWorkplace = queryParams.get('from') === "workplace" || ssoLogin;

        const isWorkPlaceLogin = this.state.user?.workplace;

        return (
            <div className={`${(fromWorkplace || isWorkPlaceLogin) ? 'fromWorkplacePage' : ''} page`}>
                {this.props.children}
            </div>
        );
    }
}