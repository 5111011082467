import * as React from 'react';
import { Component } from 'react';
import { Button, Grid, OutlinedInput, InputAdornment, IconButton, FormControl, Link, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { StatusMessage } from 'models';
import i18next from 'i18next';
import { UserService } from 'services';
import { Body } from 'components/body/body';
import { Footer } from 'components/footer/footer';
import Header from 'components/header/header';
import { Page } from 'components/page/page'
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { LoginProps } from './login-props';
import { LoginState } from './login-state';
import './login.css';
import { withCookies } from 'react-cookie';

class Login extends Component<LoginProps, LoginState> {
    private adminQuery: any = new URLSearchParams(window.location.search).get("adminLogin");
    private isAdminLogin: any = (this.adminQuery === 'true' || this.adminQuery === "True");
    private checkTimer: any;
    private isInfo: boolean = false;

    constructor(props: LoginProps) {
        super(props);
        this.state = new LoginState();
    }

    async componentDidMount() {
        if (this.props.twoFactorErrorMessage != "") {
            this.showStatusMessage(new StatusMessage(this.props.twoFactorErrorMessage, 'error'));
        }

        if (this.props.route != null) {
            const queryParams = new URLSearchParams(this.props.route.location.search);
            const ssoLogin = queryParams.get('sso');
            const fromWorkplace = queryParams.get('from') === "workplace" || ssoLogin;
            if (this.props.route.location.state && (this.props.route.location.state.from == '/branch' || this.props.route.location.state.from == '/feedback')) {
                this.isInfo = true;
            }

            if (fromWorkplace) {
                this.setState({ loginViaWorkplace: true });
                const res = await this.loginViaWorkplace();

                if (!res) {
                    if (ssoLogin) {
                        await this.loginViaSSO(ssoLogin);
                    } else {
                        this.showStatusMessage(new StatusMessage("You have to authenticate again. Please follow the instruction below.", "info"));
                        this.setState({ showSSOInstruction: true });
                    }
                }
            }
            else {
                console.debug('login via Web');
            }
        }
    }

    private async aadLogin(): Promise<void> {
        try {
            this.setState({ loading: true });
            sessionStorage.setItem('aad-login-loading', 'true'); // set this to show the loading bar while redirecting
            this.props.aadInstance.loginRedirect(this.props.aadLoginRequest);
        } catch (e) {
            console.log(e, 'error message');
            this.setState({ loading: false });
            this.showStatusMessage(new StatusMessage(e.message, 'error'));
        }
    }

    private async aadB2CLogin(): Promise<void> {
        try {
            this.setState({ loading: true });
            this.props.aadB2CInstance.loginRedirect(this.props.aadB2CLoginRequest);
        } catch (e) {
            console.log(e, 'error message');
            this.setState({ loading: false });
            this.showStatusMessage(new StatusMessage(e.message, 'error'));
        }
    }

    private async adminLogin(): Promise<void> {
        if (!this.state.username || !this.state.password) {
            return;
        }
        try {
            this.setState({ loading: true });
            const user = await UserService.login(this.state.username, this.state.password, this.props.cookies.get('fbc-locale'));
            this.setState({ loading: false });
            if (user.branches.length > 0) {
                this.props.onLogin(user);
                this.props.route.history.replace(this.props.route.location.state?.from ?? '/');
            } else {
                this.showStatusMessage(new StatusMessage('You are not assigned to any branches. Please have an administrator assign you a branch.', 'error'));
            }
        } catch (e) {
            this.setState({ loading: false });
            if (e.message.includes('500')) {
                this.showStatusMessage(new StatusMessage('Error logging in. Please try again later.', 'error'));
            }
            else {
                this.showStatusMessage(new StatusMessage(e.message, 'error'));
            }
        }
    }

    private async loginViaSSO(ssoLogin: any): Promise<void> {
        try {
            console.debug('login via SSO');
            var token = this.props.cookies.get("token");
            if (token) {
                var success = await this.loginViaToken(token);
                if (success) {
                    return;
                }
            }

            this.setState({ loading: true });
            await UserService.loadMessengerExtensionsSDK();

            var user = await UserService.loginViaSSO(ssoLogin, this.isInfo ? "info" : "rfp", this.props.cookies.get('fbc-locale'));

            this.setState({ loading: false });

            if (!user) {
                console.debug('login via SSO failed');
                this.showStatusMessage(new StatusMessage("You have to authenticate again. Please follow the instruction below.", "info"));
                this.setState({ showSSOInstruction: true });
            }
            else if (user.branches.length > 0) {
                console.debug('login via SSO success');
                this.props.cookies.set('token', user.token, { path: '/', sameSite: 'none', secure: true, maxAge: 3600 * 24 * 365 });

                this.props.onLogin(user);
                this.props.route.history.replace(this.props.route.location.state?.from ?? '/');
            } else {
                console.debug('login via SSO success, but no branches assigned');
                this.showStatusMessage(new StatusMessage('You are not assigned to any branches. Please have an administrator assign you a branch.', 'error'));
            }
        } catch (e) {
            console.error('loginViaSSO error', e);

            console.debug('login via SSO failed');

            this.showStatusMessage(new StatusMessage("Unexpected error. Please follow the instruction below.", "info"));
            this.setState({ showSSOInstruction: true });
        }
    }

    private async loginViaWorkplace(): Promise<boolean> {
        var useFallback = false;
        try {
            this.setState({ loading: true });
            await UserService.loadMessengerExtensionsSDK();

            var user;
            if (this.isInfo) {
                user = await UserService.loginViaWorkplaceInfo(this.props.cookies.get('fbc-locale'));
            }
            else {
                user = await UserService.loginViaWorkplaceRFP(this.props.cookies.get('fbc-locale'));
            }

            this.setState({ loading: false });

            if (!user) {
                useFallback = true;
            }
            else if (user.branches.length > 0) {
                this.props.onLogin(user);
                this.props.route.history.replace(this.props.route.location.state?.from ?? '/');

                console.debug('login via Workplace success');

                return true;
            } else {
                this.showStatusMessage(new StatusMessage('You are not assigned to any branches. Please have an administrator assign you a branch.', 'error'));

                console.debug('login via Workplace success but no branches assigned');
                return true;
            }
        } catch (e) {
            console.error('loginViaWorkplace error', e);
            useFallback = true;
        }

        console.debug('login via Workplace failed');

        if (useFallback) {
            var token = this.props.cookies.get("token");

            if (token) {
                var success = await this.loginViaToken(token);
                if (success) {
                    return true;
                }
            }
        }

        return false;
    }

    private async loginViaToken(token: any): Promise<boolean> {
        try {
            console.debug('login via token');

            this.setState({ loading: true });
            var user;
            if (this.isInfo) {
                user = await UserService.loginViaToken(token, 'info', this.props.cookies.get('fbc-locale'));
            }
            else {
                user = await UserService.loginViaToken(token, 'rfp', this.props.cookies.get('fbc-locale'));
            }

            this.setState({ loading: false });
            if (user.branches.length > 0) {
                // store token to cookie
                this.props.cookies.set('token', user.token, { path: '/', sameSite: 'none', secure: true, maxAge: 3600 * 24 * 365 });


                this.props.onLogin(user);
                this.props.route.history.replace(this.props.route.location.state?.from ?? '/');

                console.debug('login via token success');

                return true;
            } else {
                console.debug('login via token success, but no branches assigned');

                this.showStatusMessage(new StatusMessage('You are not assigned to any branches. Please have an administrator assign you a branch.', 'error'));
                return true;
            }
        } catch (e) {
            this.setState({ loading: false });
        }

        console.debug('login via token failed');

        this.props.cookies.remove("token");
        return false;
    }

    private updateUsername(username: any) {
        this.setState({ username: username });
    }

    private updatePassword(password: string) {
        this.setState({ password: password });
    }

    private handleClickShowPassword() {
        this.setState({ showPassword: !this.state.showPassword });
    }

    private handleMouseDownPassword(e) {
        e.preventDefault();
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    private onKeyPress(e) {
        if (e.which === 13) {
            this.adminLogin();
        }
    }

    private goToForgotPassword() {
        this.props.route.history.replace('/forgot-password');
    }

    render() {
        if (this.state.loginViaWorkplace) {
            return this.renderWorkplace();
        } else if (this.isAdminLogin || (!this.state.loginViaWorkplace && this.props.fromWorkplace)) {
            return this.renderWeb();
        } else {
            return this.renderAzure();
        }
    }

    renderWorkplace() {
        return (
            <Page>
                <Body includePadding>
                    {
                        this.state.loading ?
                            <LoadingBar />
                            :
                            null
                    }
                    {
                        <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                    }
                </Body>
            </Page>
        );
    }

    renderWeb() {
        return (
            <Page>
                {
                    this.state.showStatusMessage ? <></> :
                        <Header
                            route={this.props.route}
                            title={i18next.t('BUTTON_LOGIN')}
                            showHamburgerButton={false}
                        />
                }
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body includePadding>
                    {
                        this.state.showSSOInstruction ? (
                            <Grid container direction="column" wrap="nowrap" spacing={2}>
                                <Grid item><Typography>Step 1: Close the current pop-up</Typography></Grid>
                                <Grid item><Typography>Step 2: Open the hamburger menu and click on the menu item (Retail Food Program)</Typography></Grid>
                                <Grid item><img src="/assets/images/open-menu.png" /></Grid>
                                <Grid item><img src="/assets/images/click-menu.png" /></Grid>
                                <Grid item><Typography>Step 3: Click on action button (Retail Food Program) to login</Typography></Grid>
                                <Grid item><img src="/assets/images/click-button.png" /></Grid>
                            </Grid>
                        )
                            :
                            (
                                this.state.loading ?
                                    <LoadingBar />
                                    :
                                    <Grid container direction="column" wrap="nowrap" spacing={2}>
                                        <Grid item>
                                            <OutlinedInput
                                                required
                                                placeholder="Username"
                                                value={this.state.username}
                                                onChange={(e: any) => this.updateUsername(e.target.value)}
                                                onKeyPress={this.onKeyPress}
                                                fullWidth />
                                        </Grid>
                                        <Grid item>
                                            <FormControl fullWidth>
                                                <OutlinedInput
                                                    placeholder="Password"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    value={this.state.password}
                                                    onChange={(e: any) => this.updatePassword(e.target.value)}
                                                    onKeyPress={this.onKeyPress}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={() => this.handleClickShowPassword()}
                                                                onMouseDown={(e: any) => this.handleMouseDownPassword(e)}
                                                                edge="end">
                                                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    fullWidth />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Link
                                                className="login-forgot-password-link"
                                                component="button"
                                                variant="body2"
                                                onClick={() => {
                                                    this.goToForgotPassword();
                                                }}
                                            >
                                                {i18next.t('BUTTON_FORGOT_PASSWORD')}
                                            </Link>
                                        </Grid>
                                    </Grid>
                            )
                    }
                </Body>
                {
                    this.state.showStatusMessage ? <></> :
                        <Footer>
                            <Button variant="contained" disableElevation fullWidth color="primary" disabled={!this.state.username || !this.state.password} onClick={(e: any) => this.adminLogin()}>
                                {i18next.t('BUTTON_LOGIN')}
                            </Button>
                        </Footer>
                }
            </Page>
        );
    }

    renderAzure() {
        return (
            <>
                <Button variant="contained" disableElevation fullWidth color="primary" onClick={(e: any) => this.aadB2CLogin()}>
                    {i18next.t('BUTTON_LOGIN')}
                </Button>
                <Link
                    className="login-forgot-password-link"
                    component="button"
                    variant="body2"
                    onClick={() => {
                        this.aadLogin();
                    }}
                >
                    {i18next.t('BUTTON_AAD_LOGIN')}
                </Link>
            </>
        );
    }
}

export default withCookies(Login);
