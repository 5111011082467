export class Banner {
	id: string;
	retailerId: string;
	name: string;

	constructor(id: string, retailerId: string, name: string) {
		this.id = id;
		this.retailerId = retailerId;
		this.name = name;
	}
}
