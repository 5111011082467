import { Category } from "./category";

export class DonationCategory {
	id: string;
	donationId: string;
	categoryId: string;
	category: Category;
	fraction: number | null;

	constructor(id: string, donationId: string, categoryId: string, category: Category, fraction: number | null) {
		this.id = id;
		this.donationId = donationId;
		this.categoryId = categoryId;
		this.category = category;
		this.fraction = fraction;
	}
}