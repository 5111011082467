import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { ConfigService } from './services';
import { LogLevel } from '@azure/msal-browser';
const ERROR_MESSAGE = "AADB2C90118";

let authData;
const wrapIndexComponent = async () => {
    authData = await ConfigService.getAuthConfig();

    const aadConfig = {
        auth: {
            clientId: authData.aadClientId, // This is the ONLY mandatory field that you need to supply.
            authority: `https://login.microsoftonline.com/${authData.aadTenantId}`, // Defaults to "https://login.microsoftonline.com/common"
            redirectUri: "/", // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
            postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            return;
                        case LogLevel.Info:
                            return;
                        case LogLevel.Verbose:
                            return;
                        case LogLevel.Warning:
                            return;
                    }
                }
            }
        }
    };

    const aadB2CConfig = {
        auth: {
            clientId: authData.aadB2CClientId,
            authority: authData.aadB2CSignInAuth, // Choose SUSI as your default authority.
            knownAuthorities: [authData.aadB2CAuthDomain], // Mark your B2C tenant's domain as trusted.
            redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
            postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
            navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
        },
        cache: {
            cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return;
                    }
                    switch (level) {
                        case LogLevel.Error:
                            return;
                        case LogLevel.Info:
                            return;
                        case LogLevel.Verbose:
                            return;
                        case LogLevel.Warning:
                            return;
                        default:
                            return;
                    }
                },
            },
        },
    };

    const aadLoginRequest = {
        scopes: []
    };

    const aadB2CLoginRequest = {
        scopes: ["openid"],
    };

    let aadB2CInstance = new PublicClientApplication(aadB2CConfig);
    let aadInstance = new PublicClientApplication(aadConfig);

    // Default to using the first account if no account is active on page load
    if (!aadB2CInstance.getActiveAccount() && aadB2CInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        aadB2CInstance.setActiveAccount(aadB2CInstance.getAllAccounts()[0]);
   }

    // Default to using the first account if no account is active on page load
    if (!aadInstance.getActiveAccount() && aadInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        aadInstance.setActiveAccount(aadInstance.getAllAccounts()[0]);
    }

    aadB2CInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_FAILURE) {
            if (event.error != null && (event.error as any).errorMessage.includes(ERROR_MESSAGE)) { // The user has forgotten their password.
                const authority = authData.aadB2CForgotPasswordAuth;
                aadB2CConfig.auth.authority = authority;
                aadB2CInstance = new PublicClientApplication(aadB2CConfig);
                aadB2CInstance.loginRedirect(aadB2CLoginRequest);
            }
        }
        else if (
            (event.eventType === EventType.LOGIN_SUCCESS ||
                event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
                event.eventType === EventType.SSO_SILENT_SUCCESS) && (event.payload != null
                    && (event.payload as any).account)
        ) {
            aadB2CInstance.setActiveAccount((event.payload as any).account);
        }
        aadB2CInstance.getActiveAccount();
    });

    aadInstance.addEventCallback((event) => {
        if ((event.eventType === EventType.LOGIN_SUCCESS) && sessionStorage.getItem('aad-login-loading')) {
            sessionStorage.removeItem('aad-login-loading');
            aadInstance.loginRedirect(aadLoginRequest);
        }
        else if (event.eventType === "msal:handleRedirectEnd" && sessionStorage.getItem('aad-login-loading')) { // when users come back to RFP app login page from AAD login page by browser back 
            sessionStorage.removeItem('aad-login-loading');
            window.location.replace(`${window.location.href}`);
        }
    });

    const rootElement = document.getElementById('root');
    
    ReactDOM.render(<CookiesProvider><App aadInstance={aadInstance} aadB2CInstance={aadB2CInstance} aadLoginRequest={aadLoginRequest} aadB2CLoginRequest={aadB2CLoginRequest} showEmptyPageForFacebookUser={authData.showEmptyPageForFacebookUser} messageForFacebookUser={authData.messageForFacebookUser}  urlForFacebookUser={authData.urlForFacebookUser} /></CookiesProvider>, rootElement);
}

// wrap Index to get the auth config from appsettings
wrapIndexComponent();
