import { Category } from "./category";

export class CampaignDonationCategory {
	id: string;
	campaignId: string;
	campaignDonationId: string;
	categoryId: string;
	category: Category;
	fraction: number | null;

	constructor(id: string, campaignId: string, campaignDonationId: string, categoryId: string, category: Category, fraction: number | null) {
		this.id = id;
		this.campaignId = campaignId;
		this.campaignDonationId = campaignDonationId;
		this.categoryId = categoryId;
		this.category = category;
		this.fraction = fraction;
	}
}