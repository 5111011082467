import * as React from 'react';
import { Component } from 'react';
import { Button, Divider, List, Zoom } from '@material-ui/core';
import i18next from 'i18next'; 
import { KeyboardDatePicker } from '@material-ui/pickers';
import { BranchService, DonationService } from 'services';
import { StatusMessage } from 'models';
import { MonthlyReportsListItem } from './monthly-reports-list-item';
import Header from 'components/header/header';
import { Footer } from 'components/footer/footer';
import { Body } from 'components/body/body';
import { Page } from 'components/page/page';
import { StatusBar } from 'components/status-bar/status-bar';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { MonthlyReportsProps } from './monthly-reports-list-props';
import { MonthlyReportsState } from './monthly-reports-list-state';
import { ExportUtilities } from 'utils/export';

export class MonthlyReports extends Component<MonthlyReportsProps, MonthlyReportsState> {
    constructor(props: MonthlyReportsProps) {
        super(props);
        this.state = new MonthlyReportsState();
    }

    componentDidMount() {
        const statusMessage: StatusMessage | undefined = this.props.route.location.state?.statusMessage;
        if (statusMessage) {
            this.showStatusMessage(statusMessage);
        }

        const year: number | undefined = this.props.route.location.state?.year;
        if (year) {
            this.setState({ year: year }, () => { this.fetchDonationSummaries() });
        }
        else {
            this.fetchDonationSummaries();
        }
    }

    componentDidUpdate(prevProps: MonthlyReportsProps, prevState: MonthlyReportsState) {
        if (prevProps.user.branchId !== this.props.user.branchId) {
            this.fetchDonationSummaries();
        }
    }

    addDonation() {
        this.props.route.history.replace('/donations/add', {
            from: this.props.route.location.pathname,
            year: this.state.year
        });
    }

    async fetchDonationSummaries() {
        this.setState({ loading: true });
        try {
            const reports = await BranchService.getMonthlyReports(this.props.user.token, this.props.user.branchId, this.state.year);
            const donationYears = await DonationService.getDonationYears(this.props.user.token);

            if (donationYears != null && donationYears.length > 0) {
                this.setState({ reports: reports, minDonationYear: donationYears[donationYears.length - 1], maxDonationYear: donationYears[0] });
            }
            else {
                this.setState({ reports: reports });
            }
            
        } finally {
            this.setState({ loading: false });
        }
    }

    updateYear(date: Date | null) {
        if (date !== null && !isNaN(date.getDate())) {
            this.setState({ year: date!.getFullYear() }, () => this.fetchDonationSummaries());
        }
    }

    onBranchSelected(branchId) {
        this.props.onBranchSelected(branchId);
        this.hideStatusMessage();
    }

    async onExportDonations() {
        this.setState({ loading: true });
        try {
            var branchName = this.props.user.branches.find(b => b.branchId === this.props.user.branchId)?.branchName;
            await ExportUtilities.exportDonations(this.props.user.token, this.props.user.branchId, this.state.year, branchName);
            this.showStatusMessage(new StatusMessage(i18next.t('NOTE_DONATION_EXPORT_SUCCESS'), 'success'));
        }
        catch (e) {
            this.showStatusMessage(new StatusMessage(i18next.t('NOTE_DONATION_EXPORT_FAILED'), 'error'));
        }
        finally {
            this.setState({ loading: false });
        }
    }

    private showStatusMessage(statusMessage: StatusMessage) {
        this.setState({ showStatusMessage: true, statusMessage: statusMessage });
    }

    private hideStatusMessage() {
        this.setState({ showStatusMessage: false });
    }

    render() {
        const date = new Date(this.state.year, 0);

        return (
            <Page>
                <Header
                    route={this.props.route}
                    title={i18next.t('MONTHLY_REPORTS')}
                    branches={this.props.user.branches}
                    branchId={this.props.user.branchId}
                    user={this.props.user}
                    onLogout={(user) => this.props.onLogout(user)}
                    onBranchSelected={(branchId) => this.onBranchSelected(branchId)}
                    showExportButton={true}
                    onExportDonations={() => this.onExportDonations()}
                    siteLocale={this.props.siteLocale}
                    handleLangChange={(selectedLocale) => this.props.handleLangChange(selectedLocale)}
                    >
                    <KeyboardDatePicker
                        autoOk
                        disableToolbar                        
                        className="date-picker"
                        views={["year"]}
                        value={date}
                        minDate={new Date(this.state.minDonationYear.toString() + "-01-02")}
                        maxDate={new Date((this.state.maxDonationYear + 1).toString() + "-12-31")}
                        minDateMessage={i18next.t('VALIDATION_YEAR_RANGE')}
                        maxDateMessage={i18next.t('VALIDATION_YEAR_RANGE')}
                        onChange={(e) => this.updateYear(e)}
                        variant="inline"/>
                </Header>
                {
                    <StatusBar show={this.state.showStatusMessage} message={this.state.statusMessage} onClose={() => this.hideStatusMessage()} />
                }
                <Body>
                    {
                        this.state.loading ?
                            <LoadingBar/>
                            :
                            <List disablePadding>
                                {
                                    this.state.reports.map((report, i) => {
                                        return (
                                            <Zoom key={i} in={true} timeout={500} style={{ transitionDelay: `${i * 250}ms` }}>
                                                <div>
                                                    <MonthlyReportsListItem route={this.props.route} report={report} />
                                                    <Divider component="li" />
                                                </div>
                                            </Zoom>
                                        );
                                    })
                                }
                            </List>
                    }
                </Body>
                <Footer>
                    <Button variant="contained" disableElevation fullWidth color="primary" onClick={() => this.addDonation()}>
                        {i18next.t('BUTTON_ADD_DONATION')}
                    </Button>
                </Footer>
            </Page>
        );
    }
}
